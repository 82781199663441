@import "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap";
body {
  color: #2a2a2a;
  background-color: #fafafa;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Quicksand", sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2a2a2a;
  font-family: "Quicksand", sans-serif;
}
a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0 !important;
}
p {
  line-height: 1.8;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-20 {
  margin-top: 20px;
}
button,
input {
  outline: 0 !important;
}
.btn-outline-light {
  color: #212529;
}
.dropdown-menu {
  -webkit-box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  background-color: #fff;
  border-color: #eee;
}
.dropdown-menu .form-control {
  border-radius: 5px;
  height: 45px;
}
.progress + .progress {
  margin-top: 1rem;
}
.modal p {
  line-height: 1.8;
  color: #727e8c;
}
.form-check-input {
  margin-top: 0.2rem;
}
.form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-color: #023b1e;
}
.sidemenu-area {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  z-index: 9999;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header {
  background: #fff;
  padding: 0 20px;
  border-bottom: 1px solid #f2f4f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header .navbar-brand {
  padding: 0;
}
.sidemenu-area .sidemenu-header .navbar-brand span {
  position: relative;
  top: 3px;
  color: #2a2a2a !important;
  margin-left: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 25px;
  font-weight: 700;
}
.sidemenu-area .sidemenu-header .burger-menu {
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header .burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.sidemenu-area .sidemenu-header .burger-menu.active span.top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}
.sidemenu-area .sidemenu-header .burger-menu.active span.middle-bar {
  opacity: 0;
}
.sidemenu-area .sidemenu-header .burger-menu.active span.bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu {
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.middle-bar {
  opacity: 0;
}
.sidemenu-area .sidemenu-header .responsive-burger-menu.active span.bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.sidemenu-area .sidemenu-body {
  max-height: calc(100% - 80px);
  position: relative;
  height: 100%;
  -webkit-box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1);
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.1);
  background: #fff;
  overflow: hidden;
}
.sidemenu-area .sidemenu-body .sidemenu-nav {
  padding: 20px 0;
  list-style-type: none;
  margin-bottom: 0;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title {
  color: #686868;
  display: block;
  text-transform: uppercase;
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item-title:not(:first-child) {
  margin-top: 20px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link {
  color: #7e7e7e;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-weight: 500;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .icon {
  color: #2a2a2a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  margin-right: 5px;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .badge {
  background-color: #43d39e;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px 4px 5px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled {
  color: #a3a3a3;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link.disabled i {
  color: #a3a3a3;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link.collapsed-nav-link {
  position: relative;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link.collapsed-nav-link::after {
  content: "\e9f9";
  position: absolute;
  right: 15px;
  top: 9px;
  color: #7e7e7e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: boxicons !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover {
  background-color: #f9ecef;
  color: #023b1e;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover::before {
  width: 3px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link:hover .icon {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #023b1e;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link {
  background-color: #f9ecef;
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item.mm-active
  .nav-link::before {
  width: 3px;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item.mm-active
  .nav-link
  .icon {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item.mm-active
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level {
  list-style-type: none;
  padding-left: 30px;
  margin-bottom: 0;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link {
  color: #7e7e7e;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link
  .icon {
  color: #2a2a2a;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link::before {
  display: none;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link:hover {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .nav-link:hover
  .icon {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item.mm-active
  .nav-link {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item.mm-active
  .nav-link
  .icon {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item.mm-active
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level {
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 0;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link {
  color: #7e7e7e;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link
  .icon {
  color: #2a2a2a;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link::before {
  display: none;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link:hover {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item
  .nav-link:hover
  .icon {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item.mm-active
  .nav-link {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item.mm-active
  .nav-link
  .icon {
  color: #023b1e;
}
.sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level
  .nav-item.mm-active
  .nav-link.collapsed-nav-link::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidemenu-area.toggle-sidemenu-area {
  width: 55px;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header {
  padding: 0 10px;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: block !important;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 0;
  visibility: hidden;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .navbar-brand img {
  position: relative;
  top: -3px;
}
.sidemenu-area.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 0;
  visibility: hidden;
}
.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item-title {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link
  .menu-title {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link::after {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .nav-link
  .badge {
  display: none;
}
.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level {
  padding-left: 20px;
}
.sidemenu-area.toggle-sidemenu-area
  .sidemenu-body
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level {
  padding-left: 0;
}
.sidemenu-area:hover.toggle-sidemenu-area {
  width: 250px;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header {
  padding: 0 20px;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand span {
  opacity: 1;
  visibility: visible;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .navbar-brand img {
  top: 0;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-header .burger-menu {
  opacity: 1;
  visibility: visible;
}
.sidemenu-area:hover.toggle-sidemenu-area .sidemenu-nav .nav-item-title {
  display: block;
}
.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .nav-link
  .menu-title {
  display: inline-block;
}
.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .nav-link::after {
  display: block;
}
.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .nav-link
  .badge {
  display: inline-block;
}
.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level {
  padding-left: 30px;
}
.sidemenu-area:hover.toggle-sidemenu-area
  .sidemenu-nav
  .nav-item
  .sidemenu-nav-second-level
  .nav-item
  .sidemenu-nav-third-level {
  padding-left: 15px;
}
.top-navbar.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}
.top-navbar.navbar .responsive-burger-menu {
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.top-navbar.navbar .responsive-burger-menu span {
  height: 1px;
  width: 25px;
  background: #2a2a2a;
  display: block;
  margin: 6px 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.top-navbar.navbar .responsive-burger-menu.active span.top-bar {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}
.top-navbar.navbar .responsive-burger-menu.active span.middle-bar {
  opacity: 0;
}
.top-navbar.navbar .responsive-burger-menu.active span.bottom-bar {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.top-navbar.navbar .nav-search-form {
  position: relative;
  width: 300px;
}
.top-navbar.navbar .nav-search-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}
.top-navbar.navbar .nav-search-form .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.top-navbar.navbar .nav-search-form .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}
.top-navbar.navbar
  .nav-search-form
  .form-control:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.top-navbar.navbar .nav-search-form .form-control:focus:-ms-input-placeholder {
  color: transparent !important;
}
.top-navbar.navbar .nav-search-form .form-control:focus::-ms-input-placeholder {
  color: transparent !important;
}
.top-navbar.navbar .nav-search-form .form-control:focus::placeholder {
  color: transparent !important;
}
.top-navbar.navbar .nav-search-form .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.top-navbar.navbar .nav-search-form .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.top-navbar.navbar .nav-search-form .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.top-navbar.navbar .nav-search-form .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.top-navbar.navbar .nav-item {
  margin-left: 15px;
}
.top-navbar.navbar .nav-item .nav-link {
  padding: 0;
  color: #525f80;
  font-weight: 500;
}
.top-navbar.navbar .nav-item .nav-link.dropdown-toggle::after {
  display: none;
}
.top-navbar.navbar .nav-item .nav-link .bx-chevron-down {
  font-size: 18px;
  position: relative;
  display: inline-block;
  top: 3px;
  margin-left: -4px;
  color: #525f80;
}
.top-navbar.navbar .nav-item .nav-link img {
  width: 30px;
  margin-right: 3px;
}
.top-navbar.navbar .nav-item .nav-link .badge {
  position: absolute;
  top: -2px;
  right: -7px;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 12px;
}
.top-navbar.navbar .nav-item .nav-link .badge.badge-secondary {
  background: #023b1e;
  -webkit-animation: pulse-secondary 2s infinite;
  animation: pulse-secondary 2s infinite;
  -webkit-box-shadow: 0 0 0 rgba(255, 0, 10, 0.9);
  box-shadow: 0 0 0 rgba(255, 0, 10, 0.9);
}
.top-navbar.navbar .nav-item .nav-link .badge.badge-primary {
  background: #2962ff;
  -webkit-animation: pulse-primary 2s infinite;
  animation: pulse-primary 2s infinite;
  -webkit-box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
  box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
}
.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn {
  font-size: 22px;
  position: relative;
  cursor: pointer;
  top: 3px;
  margin-right: 5px;
}
.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn:hover {
  color: #023b1e;
}
.top-navbar.navbar .nav-item .nav-link.bx-fullscreen-btn.active i::before {
  content: "\ea5d";
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu {
  max-width: 280px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 2px;
  margin-top: 20px;
  border: none;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu.show {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px;
  position: relative;
  font-size: 15px;
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.active,
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.top-navbar.navbar .nav-item:first-child {
  margin-left: 0;
}
.top-navbar.navbar .nav-item.language-switch-nav-item .nav-link img {
  width: 25px;
  margin-right: 3px;
  position: relative;
  top: -2px;
  border-radius: 2px;
}
.top-navbar.navbar .nav-item.language-switch-nav-item.dropdown .dropdown-menu {
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.top-navbar.navbar
  .nav-item.language-switch-nav-item.dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 5px 20px;
  font-size: 14.5px;
}
.top-navbar.navbar
  .nav-item.language-switch-nav-item.dropdown
  .dropdown-menu
  .dropdown-item
  img {
  width: 27px;
  border-radius: 2px;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #023b1e;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-header
  span {
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-header
  span.mark-all-btn {
  cursor: pointer;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  padding: 7px 15px;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  background: #f7f7f7;
  text-align: center;
  color: #023b1e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  padding-left: 15px;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  span {
  color: #2a2a2a;
  margin-bottom: 1px;
  font-size: 14.5px;
  font-weight: 600;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p {
  font-size: 12px;
  color: #686868;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background-color: #023b1e;
  color: #fff;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: rgba(114, 124, 245, 0.1);
  color: #727cf5;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .icon {
  background-color: #727cf5;
  color: #fff;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon {
  background: rgba(16, 183, 89, 0.1);
  color: #10b759;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .icon {
  background-color: #10b759;
  color: #fff;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon {
  background: rgba(255, 51, 102, 0.1);
  color: #f36;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .icon {
  background-color: #f36;
  color: #fff;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon {
  background: rgba(251, 188, 6, 0.1);
  color: #fbbc06;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .icon {
  background-color: #fbbc06;
  color: #fff;
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eee;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: #023b1e !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item .notification-btn {
  font-size: 20px;
  position: relative;
  margin-right: 7px;
}
.top-navbar.navbar .nav-item .notification-btn i {
  position: relative;
  top: 3px;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  animation: ring 4s 0.7s ease-in-out infinite;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #2962ff;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-header
  span.clear-all-btn {
  cursor: pointer;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  padding: 7px 15px;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .figure
  img {
  width: 35px;
  height: 35px;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content {
  width: 285px;
  padding-left: 12px;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  span {
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 14.5px;
  font-weight: 600;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .time-text {
  font-size: 13px;
  color: #686868;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  .sub-text {
  font-size: 12.5px;
  color: #686868;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background-color: #023b1e;
  color: #fff;
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eee;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: #2962ff !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item .message-btn {
  font-size: 20px;
  position: relative;
  margin-right: 7px;
}
.top-navbar.navbar .nav-item .message-btn i {
  position: relative;
  top: 3px;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu {
  width: 350px;
  max-width: 350px;
  border-radius: 5px 5px 0 0;
  padding: 0;
  left: 0;
  right: auto;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header {
  border-radius: 5px 5px 0 0;
  background-color: #2962ff;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header span {
  font-size: 13px;
  font-weight: 600;
}
.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-header
  span.edit-btn {
  cursor: pointer;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-body {
  padding: 10px 5px;
}
.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  padding: 8px 10px;
}
.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  span {
  margin-top: 8px;
  font-weight: 600;
}
.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid #eee;
}
.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: #2962ff !important;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar .left-nav {
  position: relative;
  top: 4px;
}
.top-navbar.navbar .left-nav .nav-item .nav-link {
  font-size: 22px;
}
.top-navbar.navbar .left-nav .nav-item .nav-link:hover {
  color: #023b1e;
}
.top-navbar.navbar .right-nav {
  margin-left: 20px;
}
.top-navbar.navbar .profile-nav-item .menu-profile .name {
  color: #2a2a2a;
  display: inline-block;
  position: relative;
  top: 2px;
  font-weight: 600;
}
.top-navbar.navbar .profile-nav-item .menu-profile img {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-right: 0;
  margin-left: 5px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu {
  padding: 15px 15px 10px;
  min-width: 230px;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header {
  padding: 0;
  border-bottom: 1px solid #f2f4f9;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .figure {
  position: relative;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .figure
  img {
  width: 80px;
  height: 80px;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .name {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 17px;
  font-weight: 700;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .email {
  color: #686868;
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-body ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item {
  margin-left: 0;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link {
  color: #2a2a2a;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link.active,
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer {
  margin: 10px -15px 0;
  padding: 10px 15px 0;
  border-top: 1px solid #eee;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link {
  color: red;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link
  i {
  color: red;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link.active,
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.top-navbar.navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 250px;
  z-index: 999;
  -webkit-box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.07);
  background: #fff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: calc(100% - 250px);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 17px;
  padding-bottom: 17px;
}
.top-navbar.navbar.is-sticky.toggle-navbar-area {
  left: 55px;
  width: calc(100% - 55px);
}
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
    transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
    transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
    transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
    transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
    transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
    transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
    transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
    transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
    transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
    transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
    transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
    transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
    transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
    transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
    transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
  }
  43% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
    transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
    transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
    transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
    transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
    transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
    transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
    transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
    transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
    transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
    transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
    transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
    transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
    transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
    transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
    transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
    transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
    transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
  }
  43% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}
@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(255, 0, 10, 0.6);
    box-shadow: 0 0 0 rgba(255, 0, 10, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}
@keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(255, 0, 10, 0.6);
    box-shadow: 0 0 0 rgba(255, 0, 10, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}
@-webkit-keyframes pulse-primary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
    box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}
@keyframes pulse-primary {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
    box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px transparent;
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}
.main-content {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  min-height: 100vh;
  padding-right: 30px;
  padding-left: 280px;
}
.main-content.hide-sidemenu-area {
  padding-left: 85px;
}
.breadcrumb-area {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  padding: 20px 25px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.breadcrumb-area h1 {
  margin-bottom: 0;
  position: relative;
  color: #2a2a2a;
  padding-right: 12px;
  font-size: 20px;
  font-weight: 700;
}
.breadcrumb-area h1::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background: #cecece;
}
.breadcrumb-area .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.breadcrumb-area .breadcrumb .item {
  color: #606060;
  margin-right: 25px;
  position: relative;
  font-size: 15px;
  font-family: "Quicksand", sans-serif;
}
.breadcrumb-area .breadcrumb .item a {
  display: inline-block;
  color: #023b1e;
}
.breadcrumb-area .breadcrumb .item a:hover {
  color: #023b1e;
}
.breadcrumb-area .breadcrumb .item a i {
  font-size: 18px;
}
.breadcrumb-area .breadcrumb .item::before {
  content: "\e9fa";
  position: absolute;
  right: -21px;
  top: 2px;
  line-height: 18px;
  color: #606060;
  font-family: boxicons !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
}
.breadcrumb-area .breadcrumb .item:last-child {
  margin-right: 0;
}
.breadcrumb-area .breadcrumb .item:last-child::before {
  display: none;
}
.stats-card-box {
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  padding: 25px 25px 25px 115px;
  border-radius: 5px;
  position: relative;
}
.stats-card-box .icon-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #023b1e;
  position: absolute;
  left: 25px;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  text-align: center;
  font-size: 38px;
  color: #fff;
}
.stats-card-box .icon-box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.stats-card-box .icon-box i.bx-paper-plane {
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  left: -3px;
}
.stats-card-box .sub-title {
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
}
.stats-card-box h3 {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 700;
}
.stats-card-box h3 .badge {
  line-height: initial;
  border-radius: 0;
  color: #13bb37;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
}
.stats-card-box h3 .badge.badge-red {
  color: #ff4b00;
}
.stats-card-box .progress-list {
  margin-top: 8px;
}
.stats-card-box .progress-list .bar-inner {
  position: relative;
  width: 100%;
  border-bottom: 3px solid #d1d1d1;
}
.stats-card-box .progress-list .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: #023b1e;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
}
.stats-card-box .progress-list p {
  color: #a3a3a3;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 13px;
}
.col-lg-3:nth-child(2) .stats-card-box .icon-box {
  background-color: #13bb37;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}
.col-lg-3:nth-child(2) .stats-card-box .progress-list .bar-inner .bar {
  background: #13bb37;
}
.col-lg-3:nth-child(3) .stats-card-box .icon-box {
  background-color: #ff4b00;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}
.col-lg-3:nth-child(3) .stats-card-box .progress-list .bar-inner .bar {
  background: #ff4b00;
}
.col-lg-3:nth-child(4) .stats-card-box .icon-box {
  background-color: #4788ff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}
.col-lg-3:nth-child(4) .stats-card-box .progress-list .bar-inner .bar {
  background: #4788ff;
}
.ecommerce-stats-area {
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 25px;
}
.ecommerce-stats-area .row {
  margin-left: 0;
  margin-right: 0;
}
.ecommerce-stats-area .row .col-lg-3 {
  padding-left: 0;
  padding-right: 0;
}
.ecommerce-stats-area .row .col-lg-3:nth-child(2) .single-stats-card-box .icon {
  background-color: #13bb37;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
}
.ecommerce-stats-area .row .col-lg-3:nth-child(3) .single-stats-card-box .icon {
  background-color: #ff4b00;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
}
.ecommerce-stats-area .row .col-lg-3:nth-child(4) .single-stats-card-box {
  border-right: none;
}
.ecommerce-stats-area .row .col-lg-3:nth-child(4) .single-stats-card-box .icon {
  background-color: #4788ff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
}
.single-stats-card-box {
  padding-left: 90px;
  position: relative;
}
.single-stats-card-box .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #023b1e;
  position: absolute;
  left: 0;
  top: 46%;
  -webkit-transform: translateY(-46%);
  transform: translateY(-46%);
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.15);
  text-align: center;
  font-size: 38px;
  color: #fff;
}
.single-stats-card-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.single-stats-card-box .icon i.bx-paper-plane {
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  left: -3px;
}
.single-stats-card-box .sub-title {
  color: #a3a3a3;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
}
.single-stats-card-box h3 {
  margin-bottom: 0;
  color: #023b1e;
  position: relative;
  font-size: 30px;
  font-weight: 700;
}
.single-stats-card-box h3 .badge {
  line-height: initial;
  border-radius: 0;
  color: #13bb37;
  background-color: rgba(49, 203, 114, 0.18);
  padding: 2px 4px 1px;
  border-radius: 5px;
  position: relative;
  bottom: 3px;
  font-size: 11px;
  font-weight: 400;
}
.single-stats-card-box h3 .badge.badge-red {
  background-color: rgba(255, 91, 91, 0.18);
  color: #ff4b00;
}
.single-stats-card-box h3 .badge i {
  display: inline-block;
  margin-right: -2px;
}
.card {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border: none;
  border-radius: 5px;
  padding: 25px;
}
.card .card-header {
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 25px;
  padding: 0;
  border: none;
}
.card .card-header h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}
.card .card-header .dropdown .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}
.card .card-header .dropdown .dropdown-toggle::after {
  display: none;
}
.card .card-header .dropdown .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.card .card-header .dropdown .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item.active,
.card .card-header .dropdown .dropdown-menu .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.card .card-header .dropdown .dropdown-menu .dropdown-item:hover i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card .card-body {
  padding: 0;
}
.card .card-body .youtube-video iframe {
  width: 100%;
  border: none;
  height: 435px;
}
.card .card-body.border-primary {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-secondary {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-success {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-danger {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-warning {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-info {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-light {
  border-width: 1px;
  border-style: solid;
}
.card .card-body.border-dark {
  border-width: 1px;
  border-style: solid;
}
.activity-card-box {
  margin-top: -20px;
}
.activity-card-box .progress-list {
  margin-top: 20px;
}
.activity-card-box .progress-list span {
  display: inline-block;
  color: #919191;
  font-size: 14px;
  font-weight: 400;
}
.activity-card-box .progress-list .bar-inner {
  margin-top: 8px;
  position: relative;
  width: 100%;
  border-radius: 0 5px 5px 0;
  border-bottom: 5px solid #f2f4f4;
}
.activity-card-box .progress-list .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 5px;
  background: #023b1e;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.activity-card-box .row .col-lg-6:nth-child(2) .progress-list .bar-inner .bar {
  background-color: #33a73b;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}
.activity-card-box .row .col-lg-6:nth-child(3) .progress-list .bar-inner .bar {
  background-color: #5f7be7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}
.activity-card-box .row .col-lg-6:nth-child(4) .progress-list .bar-inner .bar {
  background-color: #ff5b5c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.activity-timeline-content ul {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 0;
  margin-top: -20px;
}
.activity-timeline-content ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  margin-top: 20px;
  font-size: 15px;
  color: #2a2a2a;
  padding-left: 55px;
  padding-right: 10px;
}
.activity-timeline-content ul li::before {
  position: absolute;
  left: 25px;
  content: "";
  bottom: 0;
  width: 1px;
  height: 150%;
  background-color: #eee;
}
.activity-timeline-content ul li i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #023b1e;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 22px;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.activity-timeline-content ul li span {
  display: block;
  font-size: 14px;
  color: #a3a3a3;
  margin-bottom: 2px;
}
.activity-timeline-content ul li:nth-child(1)::before {
  height: 100%;
}
.activity-timeline-content ul li:nth-child(2)::before {
  height: 100%;
}
.activity-timeline-content ul li:nth-child(2) i {
  background-color: #13bb37;
}
.activity-timeline-content ul li:nth-child(3) i {
  background-color: #4788ff;
}
.activity-timeline-content ul li:nth-child(4) i {
  background-color: #ffc107;
}
.best-sales-box ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.best-sales-box ul li {
  color: #9d9d9d;
  font-size: 14px;
  margin-bottom: 4px;
}
.best-sales-box ul li:last-child {
  margin-bottom: 0;
}
.best-sales-box ul li span {
  display: inline-block;
  min-width: 20px;
  margin-right: 3px;
}
.best-sales-box ul li .bar-inner {
  position: relative;
  width: 100%;
  border-radius: 0 5px 5px 0;
  border-bottom: 5px solid #f2f4f4;
}
.best-sales-box ul li .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 5px;
  background: #023b1e;
  border-radius: 0 5px 5px 0;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.browser-used-box {
  margin-left: -25px;
  margin-right: -25px;
}
.browser-used-box table {
  margin-bottom: 0;
}
.browser-used-box table thead th {
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.browser-used-box table thead th:first-child {
  padding-left: 25px;
}
.browser-used-box table thead th:last-child {
  padding-right: 25px;
}
.browser-used-box table tbody td {
  vertical-align: bottom;
  border: none;
  white-space: nowrap;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 0;
}
.browser-used-box table tbody td:first-child {
  padding-left: 25px;
}
.browser-used-box table tbody td:last-child {
  padding-right: 25px;
}
.browser-used-box table tbody td i {
  color: #13bb37;
  display: inline-block;
  margin-right: -2px;
}
.browser-used-box table tbody td i.red {
  color: #ff4b00;
}
.browser-statistics-box .box {
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}
.browser-statistics-box .box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.browser-statistics-box .box:first-child {
  margin-top: 7px;
}
.browser-statistics-box .box .img {
  background-color: #f3f3f3;
  border-radius: 50%;
  text-align: center;
  width: 45px;
  height: 40px;
  line-height: 37px;
}
.browser-statistics-box .box .content {
  margin-left: 15px;
  width: 100%;
}
.browser-statistics-box .box .content h5 {
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 600;
}
.browser-statistics-box .box .content .bar-inner {
  position: relative;
  width: 100%;
  border-bottom: 3px solid #d1d1d1;
  border-radius: 5px;
}
.browser-statistics-box .box .content .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: #023b1e;
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  border-radius: 5px;
}
.browser-statistics-box .box .content .count-box {
  margin-left: 15px;
  color: #2a2a2a;
}
.browser-statistics-box .box:nth-child(2) .content .bar-inner .bar {
  background-color: #33a73b;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}
.browser-statistics-box .box:nth-child(3) .content .bar-inner .bar {
  background-color: #5f7be7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}
.browser-statistics-box .box:nth-child(4) .content .bar-inner .bar {
  background-color: #ff5b5c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.browser-statistics-box .box:nth-child(5) .content .bar-inner .bar {
  background-color: #33a73b;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
  box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
}
.browser-statistics-box .box:nth-child(7) .content .bar-inner .bar {
  background-color: #5f7be7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
  box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
}
.browser-statistics-box .box:nth-child(8) .content .bar-inner .bar {
  background-color: #ff5b5c;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
}
.new-product-box {
  border-radius: 5px;
  margin-bottom: 25px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828d99;
  font-size: 16px;
  font-weight: 400;
}
.new-product-box .icon {
  width: 45px;
  height: 45px;
  background-color: #fbd8d7;
  color: #023b1e;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.new-product-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.new-product-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}
.new-product-box:hover .icon {
  background-color: #023b1e;
  color: #fff;
}
.new-user-box {
  margin-bottom: 25px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828d99;
  font-size: 16px;
  font-weight: 400;
}
.new-user-box .icon {
  width: 45px;
  height: 45px;
  background-color: #d0f1d7;
  color: #13bb37;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.new-user-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.new-user-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}
.new-user-box:hover .icon {
  background-color: #13bb37;
  color: #fff;
}
.upcoming-product-box {
  border-radius: 5px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  padding: 21.5px 15px;
  text-align: center;
  color: #828d99;
  font-size: 16px;
  font-weight: 400;
}
.upcoming-product-box .icon {
  width: 45px;
  height: 45px;
  background-color: #dbe5f7;
  color: #4788ff;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.upcoming-product-box .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.upcoming-product-box .sub-text {
  color: #2a2a2a;
  margin-top: 3px;
  font-size: 17px;
}
.upcoming-product-box:hover .icon {
  background-color: #4788ff;
  color: #fff;
}
.widget-todo-list {
  border-top: 1px solid #eee;
  padding-top: 18px !important;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: -10px;
  margin-top: -10px;
}
.widget-todo-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-todo-list ul li {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 9px;
  padding-bottom: 10px;
}
.widget-todo-list ul li .checkbox {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.widget-todo-list ul li .todo-item-title {
  position: relative;
  padding-left: 53px;
}
.widget-todo-list ul li .todo-item-title img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.widget-todo-list ul li .todo-item-title h3 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}
.widget-todo-list ul li .todo-item-title p {
  color: #919191;
  font-size: 15px;
  margin-bottom: 0;
}
.widget-todo-list ul li .todo-item-action {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
}
.widget-todo-list ul li .todo-item-action a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 3px;
  background-color: #c6e8cd;
  color: #13bb37;
  text-align: center;
  margin-left: 3px;
}
.widget-todo-list ul li .todo-item-action a:hover {
  background-color: #13bb37;
  color: #fff;
}
.widget-todo-list ul li .todo-item-action a.delete {
  background-color: #f9d0d9 !important;
  color: #023b1e !important;
}
.widget-todo-list ul li .todo-item-action a.delete:hover {
  background-color: #023b1e !important;
  color: #fff !important;
}
.widget-todo-list ul li:hover {
  background-color: #f2f4f4;
}
.widget-todo-list ul li:nth-child(2) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}
.widget-todo-list ul li:nth-child(2) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #fff;
}
.widget-todo-list ul li:nth-child(3) .todo-item-action a {
  background-color: #f5d1c4;
  color: #fb4a06;
}
.widget-todo-list ul li:nth-child(3) .todo-item-action a:hover {
  background-color: #fb4a06;
  color: #fff;
}
.widget-todo-list ul li:nth-child(4) .todo-item-action a {
  background-color: #d1def5;
  color: #4788ff;
}
.widget-todo-list ul li:nth-child(4) .todo-item-action a:hover {
  background-color: #4788ff;
  color: #fff;
}
.cbx {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.cbx span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #dfe3e7;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #023b1e;
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.cbx span:first-child:hover span:first-child {
  border-color: #023b1e;
}
.inp-cbx:checked + .cbx span:first-child {
  background: #023b1e;
  border-color: #023b1e;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx:checked + .cbx span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.inp-cbx + .cbx.active span:first-child {
  background: #023b1e;
  border-color: #023b1e;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}
.inp-cbx + .cbx.active span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx + .cbx.active span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
@-webkit-keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.recent-orders-box table {
  margin-bottom: 0;
}
.recent-orders-box table thead th {
  text-align: center;
  white-space: nowrap;
  padding: 0 15px 15px;
  border-bottom: 2px solid #f6f6f7;
  border-top: none;
  font-size: 15px;
}
.recent-orders-box table thead th:first-child {
  text-align: left;
  padding-left: 0;
}
.recent-orders-box table tbody tr {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.recent-orders-box table tbody tr td {
  text-align: center;
  padding: 15px;
  vertical-align: middle;
  white-space: nowrap;
  border-bottom: 1px solid #f6f6f7;
  border-top: none;
  font-size: 14.4px;
}
.recent-orders-box table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}
.recent-orders-box table tbody tr td .badge {
  padding: 0;
  background-color: transparent !important;
  color: #2a2a2a;
  font-weight: 600;
  font-size: 14.4px;
}
.recent-orders-box table tbody tr td .badge.badge-primary {
  color: #13bb37;
}
.recent-orders-box table tbody tr td .badge.badge-success {
  color: #f59d03;
}
.recent-orders-box table tbody tr td .badge.badge-danger {
  color: #ff5c75;
}
.recent-orders-box table tbody tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.recent-orders-box table tbody tr td.name {
  font-weight: 700;
}
.recent-orders-box table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}
.new-customer-box table {
  margin-bottom: 0;
}
.new-customer-box table thead th {
  text-align: center;
  border-bottom: 2px solid #f6f6f7;
  border-top: none;
  padding: 0 15px 14px;
  font-size: 15px;
}
.new-customer-box table thead th:first-child {
  text-align: left;
  padding-left: 0;
}
.new-customer-box table tbody tr {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.new-customer-box table tbody tr td {
  text-align: center;
  padding: 13px 15px;
  vertical-align: middle;
  border-bottom: 1px solid #f6f6f7;
  border-top: none;
  white-space: nowrap;
  font-size: 14.4px;
}
.new-customer-box table tbody tr td:first-child {
  text-align: left;
  padding-left: 0;
}
.new-customer-box table tbody tr td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.new-customer-box table tbody tr td .text-success {
  font-size: 16px;
}
.new-customer-box table tbody tr td .text-danger {
  font-size: 16px;
}
.new-customer-box table tbody tr td .text-warning {
  font-size: 17px;
}
.new-customer-box table tbody tr td.name {
  font-weight: 700;
}
.new-customer-box table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}
.revenue-summary-content p {
  font-size: 14px;
}
.revenue-summary-content h5 {
  font-size: 20px;
}
.revenue-summary-content h5 .primary-text {
  color: #13bb37;
  display: inline-block;
  margin-left: 1px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
}
.revenue-summary-content h5 .danger-text {
  color: #ff4b00;
  display: inline-block;
  margin-left: 1px;
  position: relative;
  bottom: 1px;
  font-size: 12px;
}
.greetings-card-box .image {
  margin-right: 20px;
  margin-top: 60px;
}
.greetings-card-box .content {
  margin-left: 10px;
  text-align: center;
}
.greetings-card-box .content .icon {
  text-align: center;
  width: 65px;
  height: 65px;
  position: relative;
  border-radius: 50%;
  background-color: #fcdedf;
  color: #023b1e;
  font-size: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: auto;
  margin-right: auto;
}
.greetings-card-box .content .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
.greetings-card-box .content h3 {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}
.greetings-card-box .content p {
  color: #727e8c;
}
.greetings-card-box:hover .content .icon {
  background-color: #023b1e;
  color: #fcdedf;
}
.social-marketing-box .list {
  position: relative;
  border-bottom: 1px solid #eee;
  margin-bottom: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 50px;
}
.social-marketing-box .list:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.social-marketing-box .list .icon {
  width: 38px;
  height: 40px;
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 1px;
  font-size: 23px;
  text-align: center;
  color: #fff;
}
.social-marketing-box .list .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
}
.social-marketing-box .list .icon.facebook {
  background-color: #4267b2;
}
.social-marketing-box .list .icon.twitter {
  background-color: #1da1f2;
}
.social-marketing-box .list .icon.instagram {
  background-color: #a1755c;
}
.social-marketing-box .list .icon.linkedin {
  background-color: #0077b5;
}
.social-marketing-box .list .icon.youtube {
  background-color: red;
}
.social-marketing-box .list .icon.github {
  background-color: #2962ff;
}
.social-marketing-box .list h4 {
  font-size: 15px;
  font-weight: 600;
}
.social-marketing-box .list h4 a {
  color: #2a2a2a;
  text-decoration: none;
}
.social-marketing-box .list h4 a:hover {
  color: #023b1e;
}
.social-marketing-box .list p {
  color: #828d99;
}
.social-marketing-box .list .stats {
  position: absolute;
  right: 0;
  top: 14px;
  font-size: 14px;
}
.top-rated-product-box ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.top-rated-product-box ul .single-product {
  position: relative;
  border-bottom: 1px solid #f6f6f7;
  margin-bottom: 15px;
  padding-left: 70px;
  padding-bottom: 15px;
  padding-right: 45px;
}
.top-rated-product-box ul .single-product .image {
  position: absolute;
  left: 0;
  top: 0;
}
.top-rated-product-box ul .single-product .image img {
  border: 1px solid #f3f3f3;
  width: 55px;
  height: 55px;
}
.top-rated-product-box ul .single-product h4 {
  font-size: 15px;
  font-weight: 700;
}
.top-rated-product-box ul .single-product h4 a {
  color: #2a2a2a;
  text-decoration: none;
}
.top-rated-product-box ul .single-product h4 a:hover {
  color: #023b1e;
}
.top-rated-product-box ul .single-product p {
  color: #686c71;
}
.top-rated-product-box ul .single-product .price {
  color: #023b1e;
}
.top-rated-product-box ul .single-product .rating i {
  color: #ffce00;
  font-size: 14px;
}
.top-rated-product-box ul .single-product .view-link {
  position: absolute;
  top: 20px;
  right: 0;
  border: 1px solid #023b1e;
  color: #023b1e;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 31px;
  border-radius: 50%;
  font-size: 15px;
}
.top-rated-product-box ul .single-product .view-link:hover {
  background-color: #023b1e;
  color: #fff;
}
.top-rated-product-box ul .single-product:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.alert .alert-link:hover {
  text-decoration: underline;
}
.demo-code-preview {
  border: 1px solid #ebebeb;
  padding: 20px 25px 15px;
  margin-top: 2rem;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
}
.demo-code-preview::before {
  content: attr(data-label);
  display: block;
  position: absolute;
  top: -9px;
  left: 20px;
  letter-spacing: 1px;
  background-color: #fff;
  font-size: 11px;
  padding: 0 5px;
  color: #023b1e;
}
.demo-code-preview .highlight .btn.btn-clipboard {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 0;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
.demo-code-preview .highlight pre {
  margin-bottom: 0;
  font-family: "Quicksand", sans-serif;
}
.demo-code-preview .highlight pre .c {
  color: #999;
}
.demo-code-preview .highlight pre .nt {
  color: #905;
}
.demo-code-preview .highlight pre .na {
  color: #690;
}
.demo-code-preview .highlight pre .s {
  color: #07a;
}
.badge-card-box .card-header {
  margin-bottom: 17px;
}
.button-card-box .card-header {
  margin-bottom: 17px;
}
.button-group-card-box .card-header {
  margin-bottom: 17px;
}
.dropdowns-card-box .card-header {
  margin-bottom: 17px;
}
.popovers-card-box .card-header {
  margin-bottom: 17px;
}
.tooltips-card-box .card-header {
  margin-bottom: 17px;
}
.spinners-card-box .card-header {
  margin-bottom: 17px;
}
.collapse-card-box .card-header {
  margin-bottom: 17px;
}
.accordion-box .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.accordion-box .accordion .accordion-item {
  display: block;
  background: #f5f5ff;
  margin-bottom: 10px;
}
.accordion-box .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-box .accordion .accordion-title {
  padding: 12px 40px 10px 20px;
  color: #2a2a2a;
  position: relative;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-weight: 600;
}
.accordion-box .accordion .accordion-title i {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
}
.accordion-box .accordion .accordion-title.active {
  background-color: #023b1e;
  color: #fff;
}
.accordion-box .accordion .accordion-title.active i::before {
  content: "\eaf8";
}
.accordion-box .accordion .accordion-title:hover {
  background-color: #023b1e;
  color: #fff;
}
.accordion-box .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px;
  font-size: 15px;
  color: #1f1b1b;
  line-height: 1.8;
  background-color: #fafafa;
}
.accordion-box .accordion .accordion-content.show {
  display: block;
}
.boxicons-list .icon-box {
  margin-bottom: 30px;
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.boxicons-list .icon-box .icon-box-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 25px;
  outline: 0;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.boxicons-list .icon-box .icon-box-inner .icon-base {
  display: inline-block;
  margin-right: 5px;
  font-size: 25px;
  position: relative;
  top: 5px;
  color: #2a2a2a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.boxicons-list .icon-box .icon-box-inner .icon-box-name {
  color: #6084a4;
  display: inline-block;
}
.boxicons-list .icon-box .icon-box-inner:hover .icon-base {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.feather-icons-list .icon-box {
  margin-bottom: 30px;
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.feather-icons-list .icon-box .icon-box-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 25px;
  outline: 0;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.feather-icons-list .icon-box .icon-box-inner .icon-base {
  display: inline-block;
  margin-right: 5px;
  color: #2a2a2a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.feather-icons-list .icon-box .icon-box-inner .icon-box-name {
  color: #6084a4;
  display: inline-block;
  position: relative;
  top: 1.5px;
}
.feather-icons-list .icon-box .icon-box-inner:hover .icon-base {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.grid-bg-example .row {
  margin-left: 0;
  margin-right: 0;
}
.grid-bg-example .row + .row {
  margin-top: 1rem;
}
.grid-bg-example .row > .col,
.grid-bg-example .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}
.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
  margin-top: 1rem;
}
.bd-example-row-flex-cols .row:first-child {
  margin-top: 0;
}
.bd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #f5f5f5;
}
.bd-example-border-utils.bd-example-border-utils-0 [class^="border"] {
  border: 1px solid #dee2e6;
}
#map {
  height: 500px;
  width: 100%;
}
.welcome-area {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: #fff;
  border-radius: 5px;
  padding-left: 25px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.welcome-content h1 {
  font-size: 40px;
  font-weight: 700;
}
.welcome-content p {
  color: #646262;
  font-size: 18px;
}
.welcome-img {
  text-align: center;
  padding-top: 20px;
}
.welcome-img img {
  width: 100%;
}
#website-analytics-chart.extra-margin {
  margin: -20px 0 -15px -20px;
}
#website-analytics-chart .apexcharts-toolbar {
  display: none;
}
#website-analytics-chart .apexcharts-legend {
  bottom: 0 !important;
}
#emailSend-chart.extra-margin {
  margin: -15px 0;
}
#activity-timeline-chart .apexcharts-legend-series {
  margin-bottom: 6px !important;
}
#activity-timeline-chart .apexcharts-legend-series:last-child {
  margin-bottom: 0 !important;
}
#activity-timeline-chart .apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 5px;
}
#traffic-source-chart.extra-margin {
  margin: -20px 0 -25px -20px;
}
#revenue-growth-chart.extra-margin {
  margin: -20px 0 -25px -20px;
}
#revenue-growth-chart.extra-margin .apexcharts-toolbar {
  display: none;
}
#revenue-summary-chart.extra-margin {
  margin: 0 0 -15px -20px;
}
#revenue-summary-chart.extra-margin .apexcharts-toolbar {
  display: none;
}
#revenue-summary-chart .apexcharts-legend {
  bottom: 0 !important;
}
#client-recollection-chart.extra-margin {
  margin: -30px 0 -15px -20px;
}
#client-recollection-chart .apexcharts-toolbar {
  display: none;
}
#client-recollection-chart .apexcharts-legend {
  bottom: 0 !important;
}
#apex-chart-datetime-x-axis .toolbar {
  margin-left: 20px;
}
#apex-chart-datetime-x-axis button {
  background: #fff;
  color: #222;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #ddd;
  border-radius: 2px;
  padding: 4px 17px;
}
#apex-chart-datetime-x-axis button.active {
  color: #fff;
  background: #008ffb;
  border: 1px solid blue;
  border-bottom: 2px solid blue;
}
.chartjs-colors .bg-purple {
  background-color: #c679e3 !important;
}
.chartjs-colors .bg-secondary {
  background-color: #f48665 !important;
}
.chartjs-colors .bg-pink {
  background-color: #ff1494 !important;
}
.chartjs-colors .bg-success-light {
  background-color: rgba(80, 196, 85, 0.3) !important;
}
.chartjs-colors .bg-primary-light {
  background-color: rgba(63, 81, 181, 0.3) !important;
}
#world-map-markers {
  height: 398px;
}
.email-content-area {
  margin-bottom: 30px;
}
.email-content-area .sidebar-left {
  float: left;
}
.email-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.email-content-area .sidebar-left .sidebar .sidebar-content .email-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary {
  color: #fff;
  background-color: #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled).active,
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled):active,
.show
  > .email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary.dropdown-toggle {
  background-color: #023b1e;
  color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.show
  > .email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:hover,
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .btn-primary:focus {
  background-color: #023b1e;
  color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:hover {
  color: #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:hover {
  color: #023b1e;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.email-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.email-content-area .content-right .email-list-wrapper .email-list {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left {
  position: relative;
  top: 3px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .checkbox {
  position: relative;
  top: -2px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon:hover {
  color: #fff;
  background-color: #023b1e;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon::after {
  display: none;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right {
  position: relative;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  label {
  position: absolute;
  left: 15px;
  color: #71738d;
  margin-bottom: 0;
  top: 14px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 2px 15px 0 39px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:focus::placeholder {
  color: transparent;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper {
  height: calc(100vh - 343px);
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e8ebf1;
  padding: 16.1px 20px;
  width: 100%;
  position: relative;
  background-color: #fff;
  font-size: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item:last-child {
  border-bottom: none;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .checkbox {
  position: relative;
  top: 2px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #c7cfd6;
  margin-top: 8px;
  margin-bottom: -5px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite.active {
  color: #fdac41;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite.active
  i::before {
  content: "\ed99";
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  div {
  position: relative;
  padding-left: 65px;
  margin-top: -2px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .from {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .msg {
  color: #71738d;
  font-size: 15px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .date {
  color: #2a2a2a;
  font-weight: 600;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read {
  background-color: #f7f7f7;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .from {
  color: #616161;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .msg {
  color: #71738d;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .date {
  color: #616161;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer {
  border-top: 1px solid #e8ebf1;
  padding: 15px 20px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  p {
  color: #71738d;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn {
  margin-bottom: -5px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 5px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button:hover {
  color: #fff;
  background-color: #023b1e;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-prev {
  border-radius: 5px 0 0 5px;
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-next {
  border-radius: 0 5px 5px 0;
}
.email-read-content-area {
  margin-bottom: 30px;
}
.email-read-content-area .sidebar-left {
  float: left;
}
.email-read-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn
  i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary {
  color: #fff;
  background-color: #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled).active,
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled):active,
.show
  > .email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary.dropdown-toggle {
  background-color: #023b1e;
  color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.show
  > .email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:hover,
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .btn-primary:focus {
  background-color: #023b1e;
  color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item:hover {
  color: #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:hover {
  color: #023b1e;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.email-read-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-read-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.email-read-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left {
  position: relative;
  top: 1px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  .go-back {
  color: #727e8c;
  font-size: 25px;
  line-height: 0;
  position: relative;
  top: -1px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  .go-back:hover {
  color: #023b1e;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  h3 {
  font-size: 18px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-left
  h3
  .badge {
  color: #fff;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 500;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right {
  position: relative;
  top: 3px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon:hover {
  color: #fff;
  background-color: #023b1e;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .btn-icon::after {
  display: none;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-list-header
  .header-right
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-read-scroll-area {
  height: calc(100vh - 338px);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper {
  padding: 20px;
  margin-bottom: 0;
  list-style-type: none;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item {
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 15px 20px;
  width: 100%;
  position: relative;
  font-size: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  background-color: #fff;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item:last-child {
  margin-bottom: 0;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .user-information {
  position: relative;
  padding-left: 65px;
  margin-top: -2px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .name {
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .name
  .email {
  color: #727e8c;
  font-weight: 400;
  font-size: 15px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .to {
  color: #71738d;
  font-size: 13px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .date {
  color: #727e8c;
  font-weight: 500;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .favorite {
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  color: #c7cfd6;
  margin-left: 12px;
  margin-right: 8px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .favorite.active {
  color: #fdac41;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .favorite.active
  i::before {
  content: "\ed99";
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-toggle::after {
  display: none;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-detail
  .email-information
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content {
  margin-top: 25px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  p {
  margin-bottom: 10px;
  color: #727e8c;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer {
  border-top: 1px solid #e8ebf1;
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-top: 20px;
  margin-right: -20px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer
  label {
  font-size: 14px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer
  ul
  li {
  margin-bottom: 5px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-read-list-item
  .email-read-list-content
  .email-read-list-footer
  ul
  li
  a {
  color: #727e8c;
  text-decoration: none;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item {
  margin-top: 30px;
  margin-bottom: 8px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a {
  -webkit-box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
  background-color: #fff;
  border: 1px solid #eee;
  padding: 10px 30px;
  border-radius: 30px;
  margin-right: 10px;
  text-decoration: none;
  color: #2a2a2a;
  font-size: 15px;
  font-weight: 700;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a:last-child {
  margin-right: 0;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a
  i {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .list-wrapper
  .email-reply-list-item
  a:hover {
  color: #fff;
  border-color: #023b1e;
  background-color: #023b1e;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer {
  border-top: 1px solid #e8ebf1;
  padding: 15px 20px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  p {
  color: #71738d;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn {
  margin-bottom: -5px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 5px;
  margin-left: 5px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button:hover {
  color: #fff;
  background-color: #023b1e;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-prev {
  border-radius: 5px 0 0 5px;
}
.email-read-content-area
  .content-right
  .email-read-list-wrapper
  .email-read-list
  .email-list-footer
  .email-pagination-btn
  button.email-pagination-next {
  border-radius: 0 5px 5px 0;
}
.email-compose-content-area {
  margin-bottom: 30px;
}
.email-compose-content-area .sidebar-left {
  float: left;
}
.email-compose-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  margin-bottom: 16px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item:hover {
  color: #023b1e;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #023b1e;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #023b1e;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:hover {
  color: #023b1e;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.email-compose-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-compose-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.email-compose-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.email-compose-content-area .content-right .email-compose-list-wrapper {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border: none;
  border-radius: 5px;
  padding: 25px;
}
.email-compose-content-area .content-right .email-compose-list-wrapper h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .card {
  background-color: transparent;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-radius: 5px;
  padding: 0;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .card
  .card-header {
  background-color: transparent;
  margin-bottom: 0;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-control {
  height: 42px;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-control:focus {
  border-color: #b1bbc4;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-editor.note-airframe,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-editor.note-frame {
  border-color: #ced4da;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-popover
  .popover-content
  > .note-btn-group,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .note-toolbar
  > .note-btn-group {
  margin-top: -1px;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 2px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  padding: 10px 25px 9.5px;
  font-size: 15px;
  font-weight: 700;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn
  i {
  position: relative;
  top: 1px;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-success {
  background-color: #3578e5;
  color: #fff;
  border-color: #3578e5;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-success:hover,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-success:focus {
  background-color: #3578e5;
  color: #fff;
  border-color: #3578e5;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
  box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-primary {
  background-color: #023b1e;
  color: #fff;
  border-color: #023b1e;
}
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-primary:hover,
.email-compose-content-area
  .content-right
  .email-compose-list-wrapper
  form
  .form-group
  .btn.btn-primary:focus {
  background-color: #e33e45;
  color: #fff;
  border-color: #e33e45;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.25) !important;
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.25) !important;
}
.todo-content-area {
  margin-bottom: 30px;
}
.todo-content-area .sidebar-left {
  float: left;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 260px;
  height: calc(100vh - 201px);
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn {
  border: none;
  padding: 12px 20px 11px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.todo-content-area .sidebar-left .sidebar .sidebar-content .todo-menu .btn i {
  font-size: 20px;
  position: absolute;
  margin-left: -23px;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary {
  color: #fff;
  background-color: #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled).active,
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled):active,
.show
  > .todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary.dropdown-toggle {
  background-color: #023b1e;
  color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:not(:disabled):not(.disabled):active:focus,
.show
  > .todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:hover,
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .btn-primary:focus {
  background-color: #023b1e;
  color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.2);
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 29px;
  text-decoration: none;
  margin-bottom: 16px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 0;
  font-size: 22px;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item:last-child {
  margin-bottom: 0;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item
  .badge {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 4px;
  position: relative;
  top: -1px;
  padding: 4px 6px;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item:hover {
  color: #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-messages
  .list-group-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-label {
  letter-spacing: 1px;
  color: #828d99;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item {
  text-decoration: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 18px;
  margin-bottom: 12px;
  background-color: #fff;
  border: none !important;
  color: #2a2a2a;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 600;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:last-child {
  margin-bottom: 0;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item.active {
  background-color: #fff;
  color: #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:hover {
  color: #023b1e;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(2)::before {
  border-color: #f79921;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(3)::before {
  border-color: #1ebe40;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(4)::before {
  border-color: #40fefe;
}
.todo-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .todo-menu
  .list-group-labels
  .list-group-item:nth-child(5)::before {
  border-color: #707070;
}
.todo-content-area .content-right {
  float: right;
  width: calc(100% - 290px);
}
.todo-content-area .content-right .todo-list-wrapper .todo-list {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header {
  padding: 15px 20px;
  border-bottom: 1px solid #e8ebf1;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left {
  position: relative;
  top: 3px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .checkbox {
  position: relative;
  top: -2px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon {
  width: 34px;
  height: 34px;
  background-color: #f3f3f3;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border: none;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon
  i {
  position: absolute;
  left: 0;
  right: 0;
  top: 47%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%);
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon:hover {
  color: #fff;
  background-color: #023b1e;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .btn-icon::after {
  display: none;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu.show {
  margin-top: 45px;
  opacity: 1;
  visibility: visible;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-left
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right {
  position: relative;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  label {
  position: absolute;
  left: 15px;
  color: #71738d;
  margin-bottom: 0;
  top: 14px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 2px 15px 0 39px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:focus::placeholder {
  color: transparent;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .todo-list-header
  .header-right
  .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.todo-content-area .content-right .todo-list-wrapper .todo-list .list-wrapper {
  height: calc(100vh - 277px);
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 1px solid #e8ebf1;
  padding-left: 60px;
  padding-right: 110px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .checkbox {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title {
  position: relative;
  padding-left: 53px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title
  img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title
  h3 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-title
  p {
  color: #919191;
  font-size: 15px;
  margin-bottom: 0;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 3px;
  background-color: #c6e8cd;
  color: #13bb37;
  text-align: center;
  margin-left: 3px;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a:hover {
  background-color: #13bb37;
  color: #fff;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a.delete {
  background-color: #f9d0d9 !important;
  color: #023b1e !important;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item
  .todo-item-action
  a.delete:hover {
  background-color: #023b1e !important;
  color: #fff !important;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
  box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(2)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(6)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(10)
  .todo-item-action
  a {
  background-color: #d1def5;
  color: #4788ff;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(2)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(6)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(10)
  .todo-item-action
  a:hover {
  background-color: #4788ff;
  color: #fff;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(3)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(7)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(11)
  .todo-item-action
  a {
  background-color: #f5d1c4;
  color: #fb4a06;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(3)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(7)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(11)
  .todo-item-action
  a:hover {
  background-color: #fb4a06;
  color: #fff;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(4)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(8)
  .todo-item-action
  a,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(12)
  .todo-item-action
  a {
  background-color: #d1def5;
  color: #4788ff;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(4)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(8)
  .todo-item-action
  a:hover,
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:nth-child(12)
  .todo-item-action
  a:hover {
  background-color: #4788ff;
  color: #fff;
}
.todo-content-area
  .content-right
  .todo-list-wrapper
  .todo-list
  .list-wrapper
  .todo-list-item:last-child {
  border-bottom: none;
}
.calendar-container {
  overflow: hidden;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  background: #fff;
}
.calendar-container .calendar {
  display: -ms-grid;
  display: grid;
  width: 100%;
  overflow: auto;
  -ms-grid-columns: (minmax(120px, 1fr)) (7);
  grid-template-columns: repeat(7, minmax(120px, 1fr));
  -ms-grid-rows: 50px;
  grid-template-rows: 50px;
  grid-auto-rows: 120px;
}
.calendar-container .calendar-header {
  position: relative;
  padding: 25px 20px;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
}
.calendar-container .calendar-header h3 {
  font-size: 18px;
  font-weight: 700;
}
.calendar-container .calendar-header button {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  background-color: #023b1e;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: 700;
}
.calendar-container .calendar-header button:hover {
  background-color: #f7911d;
  color: #fff;
}
.calendar-container .day {
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  border-right: 1px solid rgba(166, 168, 179, 0.12);
  text-align: right;
  padding: 14px 20px;
  letter-spacing: 1px;
  font-size: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #898989;
  position: relative;
  pointer-events: none;
  z-index: 1;
}
.calendar-container .day:nth-of-type(7n + 7) {
  border-right: 0;
}
.calendar-container .day:nth-of-type(n + 1):nth-of-type(-n + 7) {
  -ms-grid-row: 2;
  grid-row: 2;
}
.calendar-container .day:nth-of-type(n + 8):nth-of-type(-n + 14) {
  -ms-grid-row: 3;
  grid-row: 3;
}
.calendar-container .day:nth-of-type(n + 15):nth-of-type(-n + 21) {
  -ms-grid-row: 4;
  grid-row: 4;
}
.calendar-container .day:nth-of-type(n + 22):nth-of-type(-n + 28) {
  -ms-grid-row: 5;
  grid-row: 5;
}
.calendar-container .day:nth-of-type(n + 29):nth-of-type(-n + 35) {
  -ms-grid-row: 6;
  grid-row: 6;
}
.calendar-container .day:nth-of-type(7n + 1) {
  -ms-grid-column: 1;
  grid-column: 1/1;
}
.calendar-container .day:nth-of-type(7n + 2) {
  -ms-grid-column: 2;
  grid-column: 2/2;
}
.calendar-container .day:nth-of-type(7n + 3) {
  -ms-grid-column: 3;
  grid-column: 3/3;
}
.calendar-container .day:nth-of-type(7n + 4) {
  -ms-grid-column: 4;
  grid-column: 4/4;
}
.calendar-container .day:nth-of-type(7n + 5) {
  -ms-grid-column: 5;
  grid-column: 5/5;
}
.calendar-container .day:nth-of-type(7n + 6) {
  -ms-grid-column: 6;
  grid-column: 6/6;
}
.calendar-container .day:nth-of-type(7n + 7) {
  -ms-grid-column: 7;
  grid-column: 7/7;
}
.calendar-container .day-name {
  color: #2a2a2a;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  line-height: 50px;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
.calendar-container .day--disabled {
  background-color: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDAnIGhlaWdodD0nNDAnIHZpZXdCb3g9JzAgMCA0MCA0MCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48ZyBmaWxsPScjZjlmOWZhJyBmaWxsLW9wYWNpdHk9JzEnIGZpbGwtcnVsZT0nZXZlbm9kZCc+PHBhdGggZD0nTTAgNDBMNDAgMEgyMEwwIDIwTTQwIDQwVjIwTDIwIDQwJy8+PC9nPjwvc3ZnPg==);
  cursor: not-allowed;
}
.calendar-container .task {
  border-left-width: 3px;
  padding: 8px 12px;
  margin: 10px;
  border-left-style: solid;
  font-size: 14px;
  position: relative;
}
.calendar-container .task.task--warning {
  border-left-color: #fdb44d;
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
  grid-column: 4 / span 3;
  -ms-grid-row: 3;
  grid-row: 3;
  background: #fef0db;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #fc9b10;
  margin-top: -5px;
}
.calendar-container .task.task--danger {
  border-left-color: #fa607e;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 2 / span 3;
  -ms-grid-row: 3;
  grid-row: 3;
  margin-top: 15px;
  background: rgba(253, 197, 208, 0.7);
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #f8254e;
}
.calendar-container .task.task--info {
  border-left-color: #4786ff;
  -ms-grid-column: 6;
  -ms-grid-column-span: 2;
  grid-column: 6 / span 2;
  -ms-grid-row: 5;
  grid-row: 5;
  margin-top: 15px;
  background: rgba(218, 231, 255, 0.7);
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #0a5eff;
}
.calendar-container .task.task--primary {
  background: #4786ff;
  border: 0;
  border-radius: 4px;
  -ms-grid-column: 3;
  -ms-grid-column-span: 3;
  grid-column: 3 / span 3;
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #fff;
  -webkit-box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
  box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
}
.calendar-container .task .task__detail {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  background: #fff;
  border: 1px solid rgba(166, 168, 179, 0.2);
  color: #000;
  padding: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  z-index: 2;
}
.calendar-container .task .task__detail::after,
.calendar-container .task .task__detail::before {
  bottom: 100%;
  left: 30%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.calendar-container .task .task__detail::before {
  margin-left: -8px;
  border-bottom-color: rgba(166, 168, 179, 0.2);
  border-width: 8px;
}
.calendar-container .task .task__detail:after {
  margin-left: -6px;
  border-bottom-color: #fff;
  border-width: 6px;
}
.calendar-container .task .task__detail h4 {
  font-size: 15px;
  margin: 0;
  color: #51565d;
}
.calendar-container .task .task__detail p {
  margin-top: 4px;
  margin-bottom: 0;
  color: rgba(81, 86, 93, 0.7);
  font-weight: 500;
  font-size: 12px;
}
.chat-content-area {
  margin-bottom: 30px;
}
.chat-content-area .sidebar-left {
  float: left;
}
.chat-content-area .sidebar-left .sidebar {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  padding: 25px;
  width: 300px;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header {
  padding-bottom: 25px;
}
.chat-content-area .sidebar-left .sidebar .chat-sidebar-header form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 11.3px;
  font-size: 18px;
  color: #a8a8a8;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: #eef5f9;
  border: none;
  color: #2a2a2a;
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: #eef5f9;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus:-ms-input-placeholder {
  color: transparent !important;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus::-ms-input-placeholder {
  color: transparent !important;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus::placeholder {
  color: transparent !important;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control::-webkit-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control::-ms-input-placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control::placeholder {
  color: #a8a8a8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.chat-content-area .sidebar-left .sidebar .sidebar-content {
  height: calc(100vh - 321px);
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li {
  cursor: pointer;
  margin-bottom: 20px;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li:last-child {
  margin-bottom: 0;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar {
  position: relative;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-busy {
  background-color: #ff5b5c;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-online {
  background-color: #39da8a;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-away {
  background-color: #fdac41;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  .status-offline {
  background-color: #475f7b;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  .avatar
  [class*="status-"] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  h6 {
  margin-bottom: 3px;
  font-size: 16px;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  span {
  color: #71738d;
  font-size: 14.5px;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-label {
  color: #2a2a2a;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 1px solid #eee;
  font-size: 18px;
  font-weight: 700;
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-label::before {
  width: 70px;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #023b1e;
}
.chat-content-area .content-right {
  float: right;
  width: calc(100% - 330px);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .simplebar-scrollbar::before {
  background: #aaa;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header {
  padding: 15px 20px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  h6 {
  white-space: nowrap;
  font-size: 16px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar {
  position: relative;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-busy {
  background-color: #ff5b5c;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-online {
  background-color: #39da8a;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-away {
  background-color: #fdac41;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  .status-offline {
  background-color: #475f7b;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-left
  .avatar
  [class*="status-"] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right {
  position: relative;
  top: 1px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li {
  display: inline-block;
  margin-left: 1px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li:first-child {
  margin-left: 0;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .favorite {
  font-size: 20px;
  cursor: pointer;
  color: #c7cfd6;
  position: relative;
  top: -1px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .favorite.active {
  color: #fdac41;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .favorite.active
  i::before {
  content: "\ed99";
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #727e8c;
  font-size: 22px;
  line-height: 15px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-toggle::after {
  display: none;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: unset !important;
  transform: unset !important;
  left: auto !important;
  right: 0;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu.show {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item {
  color: #2a2a2a;
  padding: 5px 15px 5px 36px;
  position: relative;
  font-size: 14px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item
  i {
  color: #686868;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item.active,
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item:active {
  color: #2a2a2a;
  background-color: transparent;
  background-color: #f8f9fa;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-header
  .header-right
  ul
  li
  .dropdown
  .dropdown-menu
  .dropdown-item:hover
  i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container {
  height: calc(100vh - 338px);
  background-color: #e3e6f1;
  padding: 25px 20px;
  background-image: url(../img/chat-bg.png);
  background-position: center center;
  background-size: contain;
  background-repeat: repeat;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content {
  text-align: center;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-avatar {
  float: right;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body {
  overflow: hidden;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message {
  position: relative;
  float: right;
  max-width: 420px;
  text-align: left;
  padding: 12px 16px;
  margin: 10px 21.28px 21.28px 0;
  clear: both;
  word-break: break-word;
  color: #fff;
  background-color: #3578e5;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message
  p {
  color: #fff;
  margin-bottom: 0;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message
  p
  a {
  color: #fff !important;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message
  .time {
  position: absolute;
  bottom: -25px;
  right: 0;
  color: #6b7886;
  font-size: 0.8rem;
  white-space: nowrap;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message:first-child {
  margin-top: 0 !important;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-avatar {
  float: left;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message {
  text-align: left;
  float: left;
  margin: 10px 0 21.28px 21.28px;
  color: #727e8c;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message
  p {
  color: #727e8c;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message
  p
  a {
  color: #727e8c !important;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .badge-light {
  padding: 9px 20px 7px;
  text-transform: uppercase;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer {
  background-color: #fafafa;
  padding: 15px 20px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn {
  padding: 0;
  border: none;
  background-color: #f9d0d9;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  line-height: 40px;
  height: 35px;
  color: #023b1e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn:hover,
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn:focus {
  background-color: #023b1e;
  color: #fff;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn {
  padding: 0;
  border: none;
  background-color: #f9d0d9;
  border-radius: 50%;
  width: 35px;
  font-size: 19px;
  line-height: 40px;
  height: 35px;
  color: #023b1e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn:hover,
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn:focus {
  background-color: #023b1e;
  color: #fff;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form {
  position: relative;
  padding-right: 128px;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .form-control {
  background-color: #fff;
  height: 45px;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 15px;
  font-size: 14.5px;
  border: 1px solid #dfe3e7;
  color: #2a2a2a;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .form-control:focus {
  border-color: #023b1e;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .send-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  background-color: #023b1e;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  height: 45px;
  padding: 0 30px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(255, 0, 10, 0.2);
  box-shadow: 0 4px 12px 0 rgba(255, 0, 10, 0.2);
  font-size: 15px;
  font-weight: 700;
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .send-btn:hover {
  -webkit-box-shadow: 0 4px 12px 0 rgba(255, 0, 10, 0.6);
  box-shadow: 0 4px 12px 0 rgba(255, 0, 10, 0.6);
}
.profile-header {
  margin-top: 20px;
  border-radius: 5px;
  position: relative;
}
.profile-header .user-profile-images {
  position: relative;
}
.profile-header .user-profile-images .cover-image {
  border-radius: 5px;
}
.profile-header .user-profile-images .profile-image {
  position: absolute;
  left: 25px;
  bottom: -70px;
}
.profile-header .user-profile-images .profile-image img {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.41);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.41);
}
.profile-header .user-profile-images .profile-image .upload-profile-photo {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
}
.profile-header .user-profile-images .profile-image .upload-profile-photo a {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
.profile-header .user-profile-images .profile-image .upload-profile-photo a i {
  font-size: 25px;
  color: #fff;
  margin-bottom: 2px;
  display: block;
}
.profile-header
  .user-profile-images
  .profile-image:hover
  .upload-profile-photo {
  opacity: 1;
  visibility: visible;
}
.profile-header .user-profile-images .upload-cover-photo {
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}
.profile-header .user-profile-images .upload-cover-photo a {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 600;
}
.profile-header .user-profile-images .upload-cover-photo a i {
  font-size: 25px;
  color: #fff;
  position: relative;
  top: 5px;
  margin-right: 2px;
}
.profile-header .user-profile-images .upload-cover-photo a span {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.profile-header .user-profile-images .user-profile-text {
  position: absolute;
  left: 220px;
  z-index: 1;
  bottom: 15px;
}
.profile-header .user-profile-images .user-profile-text h3 {
  margin-bottom: 3px;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
}
.profile-header .user-profile-images .user-profile-text span {
  color: #fff;
  opacity: 0.9;
  font-size: 15px;
  font-weight: 600;
}
.profile-header .user-profile-images:hover .upload-cover-photo a span {
  opacity: 1;
  visibility: visible;
}
.profile-header .user-profile-nav {
  padding: 25px 25px 25px 220px;
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}
.profile-header .user-profile-nav .nav {
  padding-left: 0;
  border: none;
  margin-bottom: 0;
  list-style-type: none;
}
.profile-header .user-profile-nav .nav .nav-item {
  margin-right: 10px;
}
.profile-header .user-profile-nav .nav .nav-item .nav-link {
  color: #475f7b;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  background-color: #fff;
  background-color: #f7f7f7;
  font-size: 15px;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.profile-header .user-profile-nav .nav .nav-item .nav-link.active,
.profile-header .user-profile-nav .nav .nav-item .nav-link:hover {
  color: #fff;
  background-color: #023b1e;
}
.user-intro-box .card-body {
  text-align: center;
}
.user-intro-box .card-body button {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #f5f6f7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.user-intro-box .card-body button:hover {
  background-color: #023b1e;
  color: #fff;
  border-color: #023b1e;
}
.user-info-box .card-body ul li {
  color: #1d2129;
  margin-bottom: 10px;
}
.user-info-box .card-body ul li:last-child {
  margin-bottom: 0;
}
.user-info-box .card-body ul li span {
  line-height: 1.6;
}
.user-info-box .card-body ul li i {
  font-size: 22px;
  color: #616770;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.user-info-box .card-body ul li a {
  color: #385898;
  text-decoration: none;
}
.user-info-box .card-body ul li:hover i {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  color: #af0000;
}
.user-info-box .card-body button {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #f5f6f7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #ccd0d5;
  color: #4b4f56;
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.user-info-box .card-body button:hover {
  background-color: #023b1e;
  color: #fff;
  border-color: #023b1e;
}
.user-photos-box .card-header .photo-upload-btn {
  display: inline-block;
  border: 1px solid #afb8bd;
  color: #2a2a2a;
  border-radius: 5px;
  background-color: transparent;
  text-decoration: none;
  padding: 3px 12px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}
.user-photos-box .card-header .photo-upload-btn:hover {
  background-color: #023b1e;
  color: #fff;
  border-color: #023b1e;
}
.user-photos-box .card-body ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.user-photos-box .card-body ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.user-photos-box .card-body ul li a {
  display: block;
}
.user-skills-box .card-header .create-btn {
  display: inline-block;
  border: none;
  color: #385898;
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}
.user-skills-box .card-header .create-btn:hover {
  text-decoration: underline;
}
.user-skills-box .card-body {
  margin-bottom: -3px;
}
.user-skills-box .card-body .badge {
  background-color: #fde8e8;
  padding: 5px 8px 4px;
  color: #023b1e;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 3px;
  margin-right: 1px;
}
.user-skills-box .card-body .badge:hover {
  color: #fff;
  background-color: #023b1e;
}
.user-social-box .card-header .add-btn {
  display: inline-block;
  border: none;
  color: #385898;
  background-color: transparent;
  text-decoration: none;
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 600;
}
.user-social-box .card-header .add-btn:hover {
  text-decoration: underline;
}
.user-social-box .card-body {
  margin-bottom: -4px;
}
.user-social-box .card-body a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 40px;
  background-color: #023b1e;
  color: #fff;
  font-size: 20px;
  margin-right: 1px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration: none;
}
.user-social-box .card-body a.facebook {
  background-color: #3b5998;
  color: #fff;
}
.user-social-box .card-body a.twitter {
  background-color: #1da1f2;
  color: #fff;
}
.user-social-box .card-body a.instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #fff;
}
.user-social-box .card-body a.linkedin {
  background-color: #0077b5;
  color: #fff;
}
.user-social-box .card-body a.pinterest {
  background-color: #bd081c;
  color: #fff;
}
.user-social-box .card-body a.stack-overflow {
  background-color: #f48024;
  color: #fff;
}
.timeline-story-content .timeline-story-header .info h6 {
  color: #616770;
  margin-bottom: 3px;
}
.timeline-story-content .timeline-story-header .info h6 a {
  display: inline-block;
  color: #2a2a2a;
  font-weight: 700;
}
.timeline-story-content .timeline-story-header .info span {
  font-size: 14px;
  color: #616770;
}
.timeline-story-content .card-body p {
  color: #727e8c;
}
.timeline-story-content .card-body iframe {
  width: 100%;
  height: 400px;
  border: none;
}
.timeline-story-content .feedback-summary a {
  text-decoration: none;
  color: #686c71;
  margin-right: 15px;
  padding-right: 10px;
  border-right: 1px solid #eee;
}
.timeline-story-content .feedback-summary a i {
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-right: 3px;
}
.timeline-story-content .feedback-summary a:hover {
  color: #023b1e;
}
.timeline-story-content .feedback-summary a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.user-events-box .card-body ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.user-events-box .card-body ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.user-events-box .card-body ul li a {
  color: #616770;
  display: block;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 25px 10px;
  text-align: center;
  text-decoration: none;
}
.user-events-box .card-body ul li a i {
  margin-bottom: 10px;
  background: #3578e5;
  display: inline-block;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  font-size: 20px;
}
.user-events-box .card-body ul li a span {
  display: block;
  color: #2a2a2a;
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}
.user-events-box .card-body ul li a:hover {
  border-color: #023b1e;
}
.user-friend-request-box .card-body ul li {
  margin-bottom: 20px;
}
.user-friend-request-box .card-body ul li:last-child {
  margin-bottom: 0;
}
.user-friend-request-box .card-body ul li h6 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}
.user-friend-request-box .card-body ul li h6 a {
  color: #2a2a2a;
}
.user-friend-request-box .card-body ul li span {
  color: #616770;
  font-size: 14px;
}
.user-friend-request-box .card-body ul li span a {
  color: #616770;
}
.user-friend-request-box .card-body ul li .btn-box {
  margin-top: 5px;
}
.user-friend-request-box .card-body ul li .btn-box a {
  text-decoration: none;
  border: 1px solid #eee;
  color: #616770;
  font-size: 18px;
  display: inline-block;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 30px;
  text-align: center;
}
.user-friend-request-box .card-body ul li .btn-box a:hover {
  color: #fff;
  border-color: #023b1e;
  background-color: #023b1e;
}
.user-trends-box .card-body ul li a {
  text-decoration: none;
  color: #616770;
  font-size: 14px;
  display: block;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-trends-box .card-body ul li a span {
  display: block;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}
.user-trends-box .card-body ul li:first-child a {
  border-top: 1px solid #eee;
}
.post-share-content {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 25px;
}
.post-share-content .post-share-field {
  position: relative;
}
.post-share-content .post-share-field label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.post-share-content .post-share-field label img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.post-share-content .post-share-field .form-control {
  border: none;
  border-radius: 0;
  padding: 20px 0 0 75px;
}
.post-share-content .post-share-footer {
  border-top: 1px solid #eee;
}
.post-share-content .post-share-footer .post-share-info a {
  font-size: 25px;
  color: #828d99;
  margin-right: 5px;
  line-height: 1;
  text-decoration: none;
}
.post-share-content .post-share-footer .post-share-info a:last-child {
  margin-right: 0;
}
.post-share-content .post-share-footer .post-share-info a:hover {
  color: #023b1e;
}
.post-share-content .post-share-footer .post-btn a {
  color: #2a2a2a;
  text-decoration: none;
  border-radius: 5px;
  padding: 11px 35px 9px;
  background-color: #e2ecff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.post-share-content .post-share-footer .post-btn a:hover {
  color: #fff;
  background-color: #023b1e;
}
.user-about-me-box p {
  color: #727e8c;
  margin-bottom: 10px;
}
.user-biography-box p {
  color: #727e8c;
  margin-bottom: 10px;
}
.user-friends-box {
  padding-bottom: 10px;
}
.user-friends-box .single-friends-box {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 100%;
}
.user-friends-box .single-friends-box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.user-friends-box .single-friends-box .info h5 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
}
.user-friends-box .single-friends-box .info h5 a {
  color: #2a2a2a;
}
.user-friends-box .single-friends-box .info span {
  color: #616770;
  font-size: 14px;
}
.user-friends-box .single-friends-box .info span a {
  color: #616770;
}
.user-settings-box h3 {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 700;
}
.user-settings-box h3 i {
  width: 35px;
  height: 35px;
  line-height: 36px;
  border-radius: 50%;
  text-align: center;
  background-color: #e2ecff;
  font-size: 20px;
  margin-right: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  top: 2px;
}
.user-settings-box h3:hover i {
  color: #fff;
  background-color: #023b1e;
}
.user-settings-box h3:not(:first-child) {
  margin-top: 10px;
}
.user-settings-box form .form-group {
  margin-bottom: 20px;
}
.user-settings-box form .form-group label {
  color: #6c757d;
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
}
.user-settings-box form .form-group .form-control {
  padding-left: 15px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #2a2a2a;
  font-size: 14px;
  height: 44px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.user-settings-box form .form-group .form-control::-webkit-input-placeholder {
  color: #6c757d;
}
.user-settings-box form .form-group .form-control:-ms-input-placeholder {
  color: #6c757d;
}
.user-settings-box form .form-group .form-control::-ms-input-placeholder {
  color: #6c757d;
}
.user-settings-box form .form-group .form-control::placeholder {
  color: #6c757d;
}
.user-settings-box form .form-group .form-control:focus {
  border-color: #b1bbc4;
}
.user-settings-box form .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}
.user-settings-box form .btn-box {
  margin-top: 10px;
}
.user-settings-box form .btn-box .submit-btn {
  border-radius: 5px;
  background-color: #023b1e;
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 700;
}
.user-settings-box form .btn-box .submit-btn i {
  font-size: 20px;
  line-height: 1;
  margin-right: 2px;
  position: relative;
  top: 3px;
}
.user-settings-box form .btn-box .submit-btn:hover {
  opacity: 0.8;
}
.single-gallery-image img {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.login-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.login-area.bg-image {
  background-image: url(../img/login-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-area .d-table {
  width: 100%;
  height: 100%;
}
.login-area .d-table-cell {
  vertical-align: middle;
}
.login-area .login-form {
  max-width: 480px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.login-area .login-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.login-area .login-form form {
  margin-top: 25px;
}
.login-area .login-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.login-area .login-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.login-area .login-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.login-area .login-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.login-area .login-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.login-area
  .login-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.login-area .login-form form .form-group .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.login-area .login-form form .form-group .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.login-area .login-form form .form-group .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.login-area .login-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.login-area
  .login-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.login-area
  .login-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.login-area
  .login-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.login-area .login-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.login-area
  .login-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}
.login-area .login-form form .form-group .form-control:focus + .label-title {
  color: #a1a1a1;
}
.login-area .login-form form .form-group .form-control:focus + .label-title i {
  top: -22px;
}
.login-area .login-form form .form-group .remember-forgot {
  text-align: left;
  position: relative;
  overflow: hidden;
}
.login-area .login-form form .form-group .remember-forgot .forgot-password {
  float: right;
  color: #023b1e;
  text-decoration: none !important;
}
.login-area .login-form form .form-group .checkbox-box {
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
  color: #6c757d;
}
.login-area .login-form form .form-group .checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.login-area
  .login-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark {
  background-color: #023b1e;
}
.login-area
  .login-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark:after {
  display: block;
}
.login-area .login-form form .form-group .checkbox-box .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  overflow: hidden;
  background-color: #eee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.login-area .login-form form .form-group .checkbox-box .checkmark:after {
  content: "\e9f0";
  position: absolute;
  display: none;
  left: 0;
  top: -4.5px;
  color: #fff;
  font-family: boxicons !important;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  font-variant: normal;
}
.login-area .login-form form .form-group .checkbox-box a {
  text-decoration: none;
  color: #2a2a2a;
}
.login-area .login-form form .login-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.login-area .login-form form .login-btn:hover,
.login-area .login-form form .login-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.login-area .login-form form p {
  margin-top: 20px;
  color: #6c757d;
}
.login-area .login-form form p a {
  text-decoration: none !important;
  color: #023b1e;
}
.register-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.register-area.bg-image {
  background-image: url(../img/login-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.register-area .d-table {
  width: 100%;
  height: 100%;
}
.register-area .d-table-cell {
  vertical-align: middle;
}
.register-area .register-form {
  max-width: 700px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.register-area .register-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.register-area .register-form form {
  margin-top: 25px;
}
.register-area .register-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.register-area .register-form form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.register-area .register-form form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.register-area .register-form form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.register-area .register-form form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.register-area
  .register-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.register-area
  .register-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.register-area .register-form form .form-group .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.register-area .register-form form .form-group .form-control:focus {
  padding-left: 5px;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}
.register-area
  .register-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}
.register-area .register-form form .form-group .terms-conditions {
  text-align: left;
  position: relative;
  overflow: hidden;
}
.register-area .register-form form .form-group .checkbox-box {
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
  color: #6c757d;
}
.register-area .register-form form .form-group .checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.register-area
  .register-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark {
  background-color: #023b1e;
}
.register-area
  .register-form
  form
  .form-group
  .checkbox-box
  input:checked
  ~ .checkmark:after {
  display: block;
}
.register-area .register-form form .form-group .checkbox-box .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  overflow: hidden;
  background-color: #eee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.register-area .register-form form .form-group .checkbox-box .checkmark:after {
  content: "\e9f0";
  position: absolute;
  display: none;
  left: 0;
  top: -4.5px;
  color: #fff;
  font-family: boxicons !important;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  font-variant: normal;
}
.register-area .register-form form .form-group .checkbox-box a {
  text-decoration: none;
  color: #2a2a2a;
}
.register-area .register-form form .register-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.register-area .register-form form .register-btn:hover,
.register-area .register-form form .register-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.register-area .register-form form p {
  margin-top: 20px;
  color: #6c757d;
}
.register-area .register-form form p a {
  text-decoration: none !important;
  color: #023b1e;
}
.reset-password-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.reset-password-area.bg-image {
  background-image: url(../img/reset-password-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.reset-password-area .d-table {
  width: 100%;
  height: 100%;
}
.reset-password-area .d-table-cell {
  vertical-align: middle;
}
.reset-password-area .reset-password-content {
  max-width: 900px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.reset-password-area .reset-password-content .image {
  text-align: center;
}
.reset-password-area .reset-password-content .reset-password-form {
  padding-left: 30px;
}
.reset-password-area .reset-password-content .reset-password-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.reset-password-area .reset-password-content .reset-password-form form {
  margin-top: 25px;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .label-title
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus {
  padding-left: 5px;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .reset-password-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .reset-password-btn:hover,
.reset-password-area
  .reset-password-content
  .reset-password-form
  form
  .reset-password-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.forgot-password-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.forgot-password-area.bg-image {
  background-image: url(../img/login-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.forgot-password-area .d-table {
  width: 100%;
  height: 100%;
}
.forgot-password-area .d-table-cell {
  vertical-align: middle;
}
.forgot-password-area .forgot-password-content {
  max-width: 900px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.forgot-password-area .forgot-password-content .image {
  text-align: center;
}
.forgot-password-area .forgot-password-content .forgot-password-form {
  padding-left: 30px;
}
.forgot-password-area .forgot-password-content .forgot-password-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.forgot-password-area .forgot-password-content .forgot-password-form p {
  color: #6c757d;
  margin-top: 7px;
}
.forgot-password-area .forgot-password-content .forgot-password-form form {
  margin-top: 25px;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .label-title
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus {
  padding-left: 5px;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .forgot-password-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .forgot-password-btn:hover,
.forgot-password-area
  .forgot-password-content
  .forgot-password-form
  form
  .forgot-password-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.lock-screen-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.lock-screen-area.bg-image {
  background-image: url(../img/lock-screen-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.lock-screen-area .d-table {
  width: 100%;
  height: 100%;
}
.lock-screen-area .d-table-cell {
  vertical-align: middle;
}
.lock-screen-area .lock-screen-content {
  max-width: 900px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.lock-screen-area .lock-screen-content .image {
  text-align: center;
}
.lock-screen-area .lock-screen-content .lock-screen-form {
  padding-left: 30px;
}
.lock-screen-area .lock-screen-content .lock-screen-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.lock-screen-area .lock-screen-content .lock-screen-form form {
  margin-top: 25px;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .form-group {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .label-title
  i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus {
  padding-left: 5px;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}
.lock-screen-area .lock-screen-content .lock-screen-form form .lock-screen-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .lock-screen-btn:hover,
.lock-screen-area
  .lock-screen-content
  .lock-screen-form
  form
  .lock-screen-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.error-404-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.error-404-area.bg-image {
  background-image: url(../img/error-404-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.error-404-area .d-table {
  width: 100%;
  height: 100%;
}
.error-404-area .d-table-cell {
  vertical-align: middle;
}
.error-404-area .notfound {
  position: relative;
  max-width: 520px;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-404-area .notfound .notfound-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.error-404-area .notfound .notfound-bg div {
  width: 100%;
  background: #fff;
  border-radius: 90px;
  height: 125px;
}
.error-404-area .notfound .notfound-bg div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
}
.error-404-area .notfound .notfound-bg div:nth-child(2) {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 10;
}
.error-404-area .notfound .notfound-bg div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 90;
}
.error-404-area .notfound h1 {
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5px;
  background: url(../img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}
.error-404-area .notfound h3 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 25px;
}
.error-404-area .notfound p {
  color: #6084a4;
  margin-bottom: 20px;
  line-height: 1.7;
}
.error-404-area .notfound .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.error-404-area .notfound .default-btn:hover,
.error-404-area .notfound .default-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.error-500-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.error-500-area.bg-image {
  background-image: url(../img/error-500-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.error-500-area .d-table {
  width: 100%;
  height: 100%;
}
.error-500-area .d-table-cell {
  vertical-align: middle;
}
.error-500-area .notfound {
  position: relative;
  max-width: 520px;
  z-index: 1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-500-area .notfound .notfound-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.error-500-area .notfound .notfound-bg div {
  width: 100%;
  background: #fff;
  border-radius: 90px;
  height: 125px;
}
.error-500-area .notfound .notfound-bg div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
}
.error-500-area .notfound .notfound-bg div:nth-child(2) {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 10;
}
.error-500-area .notfound .notfound-bg div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0 0 #f3f3f3;
  box-shadow: 5px 5px 0 0 #f3f3f3;
  position: relative;
  z-index: 90;
}
.error-500-area .notfound h1 {
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 5px;
  background: url(../img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}
.error-500-area .notfound h3 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 25px;
}
.error-500-area .notfound p {
  color: #6084a4;
  margin-bottom: 20px;
  line-height: 1.7;
}
.error-500-area .notfound .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.error-500-area .notfound .default-btn:hover,
.error-500-area .notfound .default-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.coming-soon-area.bg-image {
  background-image: url(../img/coming-soon-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.coming-soon-area .d-table {
  width: 100%;
  height: 100%;
}
.coming-soon-area .d-table-cell {
  vertical-align: middle;
}
.coming-soon-area .coming-soon-content {
  max-width: 850px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-area .coming-soon-content .logo {
  display: inline-block;
}
.coming-soon-area .coming-soon-content h2 {
  font-size: 35px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 0;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content #timer div {
  background-color: #333;
  color: #fff;
  width: 100px;
  height: 110px;
  border-radius: 5px;
  font-size: 35px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  margin-top: -4px;
  font-size: 15px;
  font-weight: 500;
}
.coming-soon-area .coming-soon-content form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}
.coming-soon-area .coming-soon-content form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: #2a2a2a;
}
.coming-soon-area .coming-soon-content form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 9px;
  font-size: 22px;
}
.coming-soon-area .coming-soon-content form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #023b1e;
}
.coming-soon-area .coming-soon-content form .form-group .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #adadad;
  padding: 0 0 0 32px;
  color: #2a2a2a;
  height: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: "Quicksand", sans-serif;
  font-size: 17px;
  font-weight: 500;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control::placeholder {
  color: #a1a1a1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.coming-soon-area .coming-soon-content form .form-group .form-control:focus {
  padding-left: 5px;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus
  + .label-title::before {
  width: 100%;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus
  + .label-title {
  color: #a1a1a1;
}
.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .form-control:focus
  + .label-title
  i {
  top: -22px;
}
.coming-soon-area .coming-soon-content form .default-btn {
  padding: 12px 35px 11px;
  border-radius: 30px;
  border: none;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.coming-soon-area .coming-soon-content form .default-btn:hover,
.coming-soon-area .coming-soon-content form .default-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.coming-soon-area .coming-soon-content form p {
  color: #6084a4;
  line-height: 1.7;
  margin-bottom: 0;
  margin-top: 20px;
}
.maintenance-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.maintenance-area.bg-image {
  background-image: url(../img/maintenance-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.maintenance-area .d-table {
  width: 100%;
  height: 100%;
}
.maintenance-area .d-table-cell {
  vertical-align: middle;
}
.maintenance-area .maintenance-content {
  max-width: 850px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.maintenance-area .maintenance-content .logo {
  display: inline-block;
}
.maintenance-area .maintenance-content h2 {
  background: url(../img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 5px;
}
.maintenance-area .maintenance-content p {
  color: #6084a4;
  line-height: 1.7;
  font-size: 17px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 0;
}
.maintenance-area .maintenance-content .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.maintenance-area .maintenance-content .default-btn:hover,
.maintenance-area .maintenance-content .default-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.not-authorized-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
}
.not-authorized-area.bg-image {
  background-image: url(../img/not-authorized-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.not-authorized-area .d-table {
  width: 100%;
  height: 100%;
}
.not-authorized-area .d-table-cell {
  vertical-align: middle;
}
.not-authorized-area .not-authorized-content {
  max-width: 850px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 50px 60px 50px 30px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}
.not-authorized-area .not-authorized-content .logo {
  display: inline-block;
}
.not-authorized-area .not-authorized-content h2 {
  background: url(../img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 5px;
}
.not-authorized-area .not-authorized-content p {
  color: #6084a4;
  line-height: 1.7;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 0;
}
.not-authorized-area .not-authorized-content .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.not-authorized-area .not-authorized-content .default-btn:hover,
.not-authorized-area .not-authorized-content .default-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.faq-search {
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 35px 25px;
}
.faq-search h2 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}
.faq-search form {
  max-width: 600px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}
.faq-search form .form-control {
  background-color: #fff;
  color: #2a2a2a;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 55px;
  font-size: 16px;
  padding-left: 20px;
}
.faq-search form .form-control::-webkit-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.faq-search form .form-control:-ms-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.faq-search form .form-control::-ms-input-placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.faq-search form .form-control::placeholder {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.faq-search form .form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.faq-search form .form-control:focus:-ms-input-placeholder {
  color: transparent;
}
.faq-search form .form-control:focus::-ms-input-placeholder {
  color: transparent;
}
.faq-search form .form-control:focus::placeholder {
  color: transparent;
}
.faq-search form button {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  text-transform: uppercase;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 12px 30px 11px;
  background-color: #023b1e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.faq-search form button:hover {
  opacity: 0.5;
}
.faq-search p {
  margin-bottom: 0;
  color: #6084a4;
}
.faq-area {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 35px 25px;
}
.faq-area .title {
  margin-bottom: 50px;
  text-align: center;
}
.faq-area .title h2 {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
}
.faq-area .title p {
  color: #6084a4;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.faq-accordion-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 50px;
  text-align: center;
}
.faq-accordion-tab .tabs li {
  margin-left: 10px;
  margin-right: 10px;
}
.faq-accordion-tab .tabs li a {
  color: #2a2a2a;
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  display: block;
  -webkit-box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  font-size: 15px;
  font-weight: 700;
}
.faq-accordion-tab .tabs li a i {
  font-size: 30px;
}
.faq-accordion-tab .tabs li a span {
  display: block;
  margin-top: 5px;
}
.faq-accordion-tab .tabs li a:hover {
  background-color: #023b1e;
  color: #fff;
}
.faq-accordion-tab .tabs li.current a {
  background-color: #023b1e;
  color: #fff;
}
.faq-accordion {
  padding-left: 20px;
  padding-right: 20px;
}
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #fff;
  margin-bottom: 10px;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-title {
  padding: 15px 20px 10px 51px;
  color: #2a2a2a;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 700;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #023b1e;
  color: #fff;
  font-size: 22px;
}
.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.faq-accordion .accordion .accordion-title.active i::before {
  content: "\eaf8";
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  color: #727e8c;
  padding: 15px;
  border-top: 1px solid #eee;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}
.tab_content .tabs_item {
  display: none;
}
.tab_content .tabs_item:first-child {
  display: block;
}
.single-pricing-box {
  margin-bottom: 30px;
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  padding: 40px 30px 28px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-pricing-box .pricing-header {
  position: relative;
  z-index: 1;
  background-color: #023b1e;
  margin: -40px -30px 30px;
  border-radius: 5px 5px 0 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 25px;
}
.single-pricing-box .pricing-header h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.single-pricing-box .price {
  color: #2a2a2a;
  line-height: 50px;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  position: relative;
  z-index: 1;
  font-size: 40px;
  font-weight: 700;
}
.single-pricing-box .price sub {
  line-height: 16px;
  color: #6084a4;
  margin: 0 -8px 0 -9px;
  position: relative;
  top: -2px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
}
.single-pricing-box .price-features-list {
  text-align: left;
  padding: 30px;
  margin: 25px -30px 30px;
  list-style-type: none;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}
.single-pricing-box .price-features-list li {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 25px;
  color: #6084a4;
  font-weight: 500;
  font-size: 16px;
}
.single-pricing-box .price-features-list li i {
  font-size: 20px;
  color: #023b1e;
  position: absolute;
  right: 0;
  top: 10px;
}
.single-pricing-box .price-features-list li i.bx-check-double {
  color: #6ac04f;
}
.single-pricing-box .price-features-list li i.bx-x {
  color: #dd5252;
}
.single-pricing-box .get-started-btn {
  display: inline-block;
  color: #023b1e;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}
.single-pricing-box .get-started-btn::before {
  width: 0;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #023b1e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-pricing-box .get-started-btn:hover::before {
  width: 100%;
}
.single-pricing-box.red .pricing-header {
  background-color: #fc6765;
}
.single-pricing-box.red .get-started-btn {
  color: #fc6765;
}
.single-pricing-box.red .get-started-btn::before {
  background-color: #fc6765;
}
.single-pricing-box.orange .pricing-header {
  background-color: #ffa143;
}
.single-pricing-box.orange .get-started-btn {
  color: #ffa143;
}
.single-pricing-box.orange .get-started-btn::before {
  background-color: #ffa143;
}
.single-pricing-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.invoice-area {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 5px;
  padding: 25px;
}
.invoice-header h3 {
  font-size: 25px;
  font-weight: 700;
}
.invoice-header p {
  font-weight: 600;
  font-size: 15px;
}
.invoice-middle h4 {
  font-size: 18px;
  font-weight: 700;
}
.invoice-middle h5 {
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 700;
}
.invoice-middle h5 sub {
  margin-left: 50px;
  bottom: 0;
  width: 90px;
  display: inline-block;
  color: #6084a4;
  font-weight: 500;
  font-size: 15px;
}
.invoice-middle span {
  font-weight: 600;
  font-size: 15px;
}
.invoice-table table {
  margin-bottom: 0;
}
.invoice-table table thead th {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  font-size: 15px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.invoice-table table tbody td {
  vertical-align: middle;
  color: #6084a4;
  border-width: 1px;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.invoice-table table tbody td.total,
.invoice-table table tbody td.total-price {
  color: #2a2a2a;
  font-size: 17px;
}
.invoice-btn-box .default-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #023b1e;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
}
.invoice-btn-box .default-btn:hover,
.invoice-btn-box .default-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.invoice-btn-box .optional-btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 13px 35px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: 0.5s;
  background-color: #3578e5;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}
.invoice-btn-box .optional-btn:hover,
.invoice-btn-box .optional-btn:focus {
  background-color: #f7911d;
  color: #fff;
}
.timeline {
  position: relative;
}
.timeline::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 100%;
  background-color: #023b1e;
  left: 14px;
  top: 5px;
  border-radius: 4px;
}
.timeline .timeline-month {
  position: relative;
  padding: 4px 15px 4px 35px;
  background-color: #023b1e;
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 700;
}
.timeline .timeline-month::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100%;
  left: 5px;
  top: 5px;
}
.timeline .timeline-section {
  padding-left: 35px;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.timeline .timeline-section .timeline-date {
  margin-bottom: 15px;
  padding: 3px 15px;
  background: #3578e5;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
}
.timeline .timeline-section::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  background-color: #3578e5;
  top: 12px;
  left: 20px;
}
.timeline .timeline-section::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #3578e5;
  top: 7px;
  left: 11px;
  border-radius: 50%;
}
.timeline .timeline-section .col-lg-4 {
  margin-bottom: 15px;
}
.timeline .timeline-section .timeline-box {
  position: relative;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  border-radius: 15px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}
.timeline .timeline-section .timeline-box .box-title {
  padding: 10px 15px;
  font-weight: 600;
}
.timeline .timeline-section .timeline-box .box-title i {
  margin-right: 2px;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.timeline .timeline-section .timeline-box .box-content {
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #eee;
}
.timeline .timeline-section .timeline-box .box-content .box-item {
  margin-bottom: 10px;
  color: #606060;
  font-size: 14px;
}
.timeline .timeline-section .timeline-box .box-content .box-item:last-child {
  margin-bottom: 0;
}
.timeline .timeline-section .timeline-box .box-content .box-item strong {
  color: #2a2a2a;
  font-style: italic;
}
.timeline .timeline-section .timeline-box .box-footer {
  padding: 10px 15px;
  background-color: #f7911d;
  text-align: right;
  font-style: italic;
  color: #fff;
}
.footer-area {
  padding-bottom: 25px;
  border-top: 1px solid #eee;
  padding-top: 25px;
}
.footer-area p {
  font-size: 15px;
  margin-bottom: 0;
  color: #686868;
}
.footer-area p i {
  position: relative;
  top: 2px;
}
.footer-area p a {
  display: inline-block;
  color: #686868;
}
.footer-area p a:hover {
  color: #023b1e;
  text-decoration: none;
}
