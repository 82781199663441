/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

:root {
  --primary-color: #af0000;
  --primary-color-2: #9a0000;
  --secondary-color: #ffffff;
  --accent-color: #636363;
  --text-color: #363636;
  --text-color-2: #636363;
  --scaffold-background-color: #f0eeee;
  --active-link-bg-color: #faeae5;
  --app-toggle-btn-color: #c94242;
  --sidenav-bg-color: #1d1b1b;
  --sidenav-shadow-color: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --white: #ffffff;
  --teal: teal;
  --error: #af0000;
  --warning: orange;
  --form-control-placeholder-color: #636363;
  --track-input: linear-gradient(
    45deg,
    rgb(250, 237, 237) 45%,
    rgb(250, 237, 237) 25%,
    rgb(250, 237, 237) 30%
  );
  --chat-bg-left-color: #212121;
  --chat-bg-right-color: #000000;
  --auth-form-area-bg: linear-gradient(45deg, #ccc 45%, #fff 25%, #ccc 30%);
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  background: var(--scaffold-background-color);
  color: var(--text-color);
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: var(--primary-color-2);
}

.teal {
  background-color: var(--teal);
  color: white;
}
.cyan {
  background-color: cyan;
  color: white;
}
.orange {
  background-color: orange;
  color: white;
}
.blue-grey {
  background-color: #263341;
  color: white;
}

.error {
  background-color: var(--error);
  color: white;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
  font-family: "Quicksand", sans-serif;
}

.teal-text {
  color: teal;
}
.red-text {
  color: red;
}
.orange-text {
  color: orange;
}

.navbar-brand img {
  max-width: 40px;
  height: 40px;
}
.sidemenu-area .sidemenu-header .navbar-brand span {
  color: var(--text-color) !important;
  margin-left: 5px;
  transition: 0.5s;
  font-size: 25px;
  font-weight: 300;
}

.sidemenu-area .sidemenu-header .burger-menu span {
  width: 20px;
  background: var(--accent-color);
}

.sidemenu-area {
  width: 250px;
}
.sidemenu-area .sidemenu-header {
  background: var(--sidenav-bg-color);
  border-bottom: 1px solid var(--scaffold-background-color);
}
.sidemenu-area .sidemenu-body {
  background: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}

.btn-primary {
  background: var(--primary-color);
  border: none !important;
}
.btn-primary:hover {
  background: var(--primary-color-2);
  border: none !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-105 {
  margin-top: 105px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-115 {
  margin-top: 115px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-125 {
  margin-top: 125px;
}
.mt-130 {
  margin-top: 130px;
}
.mt-135 {
  margin-top: 135px;
}
.mt-140 {
  margin-top: 140px;
}
.mt-145 {
  margin-top: 145px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-155 {
  margin-top: 155px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-165 {
  margin-top: 165px;
}
.mt-170 {
  margin-top: 170px;
}
.mt-175 {
  margin-top: 175px;
}
.mt-180 {
  margin-top: 180px;
}
.mt-185 {
  margin-top: 185px;
}
.mt-190 {
  margin-top: 190px;
}
.mt-195 {
  margin-top: 195px;
}
.mt-200 {
  margin-top: 200px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-105 {
  margin-bottom: 105px;
}
.mb-110 {
  margin-bottom: 110px;
}
.mb-115 {
  margin-bottom: 115px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-125 {
  margin-bottom: 125px;
}
.mb-130 {
  margin-bottom: 130px;
}
.mb-135 {
  margin-bottom: 135px;
}
.mb-140 {
  margin-bottom: 140px;
}
.mb-145 {
  margin-bottom: 145px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-155 {
  margin-bottom: 155px;
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-165 {
  margin-bottom: 165px;
}
.mb-170 {
  margin-bottom: 170px;
}
.mb-175 {
  margin-bottom: 175px;
}
.mb-180 {
  margin-bottom: 180px;
}
.mb-185 {
  margin-bottom: 185px;
}
.mb-190 {
  margin-bottom: 190px;
}
.mb-195 {
  margin-bottom: 195px;
}
.mb-200 {
  margin-bottom: 200px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-105 {
  margin-left: 105px;
}
.ml-110 {
  margin-left: 110px;
}
.ml-115 {
  margin-left: 115px;
}
.ml-120 {
  margin-left: 120px;
}
.ml-125 {
  margin-left: 125px;
}
.ml-130 {
  margin-left: 130px;
}
.ml-135 {
  margin-left: 135px;
}
.ml-140 {
  margin-left: 140px;
}
.ml-145 {
  margin-left: 145px;
}
.ml-150 {
  margin-left: 150px;
}
.ml-155 {
  margin-left: 155px;
}
.ml-160 {
  margin-left: 160px;
}
.ml-165 {
  margin-left: 165px;
}
.ml-170 {
  margin-left: 170px;
}
.ml-175 {
  margin-left: 175px;
}
.ml-180 {
  margin-left: 180px;
}
.ml-185 {
  margin-left: 185px;
}
.ml-190 {
  margin-left: 190px;
}
.ml-195 {
  margin-left: 195px;
}
.ml-200 {
  margin-left: 200px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-105 {
  margin-right: 105px;
}
.mr-110 {
  margin-right: 110px;
}
.mr-115 {
  margin-right: 115px;
}
.mr-120 {
  margin-right: 120px;
}
.mr-125 {
  margin-right: 125px;
}
.mr-130 {
  margin-right: 130px;
}
.mr-135 {
  margin-right: 135px;
}
.mr-140 {
  margin-right: 140px;
}
.mr-145 {
  margin-right: 145px;
}
.mr-150 {
  margin-right: 150px;
}
.mr-155 {
  margin-right: 155px;
}
.mr-160 {
  margin-right: 160px;
}
.mr-165 {
  margin-right: 165px;
}
.mr-170 {
  margin-right: 170px;
}
.mr-175 {
  margin-right: 175px;
}
.mr-180 {
  margin-right: 180px;
}
.mr-185 {
  margin-right: 185px;
}
.mr-190 {
  margin-right: 190px;
}
.mr-195 {
  margin-right: 195px;
}
.mr-200 {
  margin-right: 200px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-110 {
  padding-top: 110px;
}
.pt-115 {
  padding-top: 115px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-125 {
  padding-top: 125px;
}
.pt-130 {
  padding-top: 130px;
}
.pt-135 {
  padding-top: 135px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-145 {
  padding-top: 145px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-155 {
  padding-top: 155px;
}
.pt-160 {
  padding-top: 160px;
}
.pt-165 {
  padding-top: 165px;
}
.pt-170 {
  padding-top: 170px;
}
.pt-175 {
  padding-top: 175px;
}
.pt-180 {
  padding-top: 180px;
}
.pt-185 {
  padding-top: 185px;
}
.pt-190 {
  padding-top: 190px;
}
.pt-195 {
  padding-top: 195px;
}
.pt-200 {
  padding-top: 60px;
}
.pt-260 {
  padding-top: 260px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-top: 30px;
}
.pb-105 {
  padding-bottom: 105px;
}
.pb-110 {
  padding-bottom: 110px;
}
.pb-115 {
  padding-bottom: 115px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-125 {
  padding-bottom: 125px;
}
.pb-130 {
  padding-bottom: 130px;
}
.pb-135 {
  padding-bottom: 135px;
}
.pb-140 {
  padding-bottom: 140px;
}
.pb-145 {
  padding-bottom: 145px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-155 {
  padding-bottom: 155px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-165 {
  padding-bottom: 165px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-175 {
  padding-bottom: 175px;
}
.pb-180 {
  padding-bottom: 180px;
}
.pb-185 {
  padding-bottom: 185px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pb-195 {
  padding-bottom: 195px;
}
.pb-200 {
  padding-bottom: 200px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-105 {
  padding-left: 105px;
}
.pl-110 {
  padding-left: 110px;
}
.pl-115 {
  padding-left: 115px;
}
.pl-120 {
  padding-left: 120px;
}
.pl-125 {
  padding-left: 125px;
}
.pl-130 {
  padding-left: 130px;
}
.pl-135 {
  padding-left: 135px;
}
.pl-140 {
  padding-left: 140px;
}
.pl-145 {
  padding-left: 145px;
}
.pl-150 {
  padding-left: 150px;
}
.pl-155 {
  padding-left: 155px;
}
.pl-160 {
  padding-left: 160px;
}
.pl-165 {
  padding-left: 165px;
}
.pl-170 {
  padding-left: 170px;
}
.pl-175 {
  padding-left: 175px;
}
.pl-180 {
  padding-left: 180px;
}
.pl-185 {
  padding-left: 185px;
}
.pl-190 {
  padding-left: 190px;
}
.pl-195 {
  padding-left: 195px;
}
.pl-200 {
  padding-left: 200px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-105 {
  padding-right: 105px;
}
.pr-110 {
  padding-right: 110px;
}
.pr-115 {
  padding-right: 115px;
}
.pr-120 {
  padding-right: 120px;
}
.pr-125 {
  padding-right: 125px;
}
.pr-130 {
  padding-right: 130px;
}
.pr-135 {
  padding-right: 135px;
}
.pr-140 {
  padding-right: 140px;
}
.pr-145 {
  padding-right: 145px;
}
.pr-150 {
  padding-right: 150px;
}
.pr-155 {
  padding-right: 155px;
}
.pr-160 {
  padding-right: 160px;
}
.pr-165 {
  padding-right: 165px;
}
.pr-170 {
  padding-right: 170px;
}
.pr-175 {
  padding-right: 175px;
}
.pr-180 {
  padding-right: 180px;
}
.pr-185 {
  padding-right: 185px;
}
.pr-190 {
  padding-right: 190px;
}
.pr-195 {
  padding-right: 195px;
}
.pr-200 {
  padding-right: 200px;
}

.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.heartbeat {
  animation: heartbeat 1s infinite alternate;
}
@-webkit-keyframes heartbeat {
  to {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.preloader {
  background-color: var(--scaffold-background-color);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}
.preloader .preloader-circle {
  width: 200px;
  height: 200px;
  position: relative;
  border-style: solid;
  border-width: 10px;
  border-top-color: var(--primary-color);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
  background-color: var(--scaffold-background-color);
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}
.preloader .preloader-circle2 {
  border-top-color: var(--primary-color);
}
.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}
.preloader .preloader-img img {
  max-width: 192px;
}
.preloader .pere-text strong {
  font-weight: 800;
  color: var(--accent-color);
  text-transform: uppercase;
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}
@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.spacer {
  flex: 1 1 auto;
}

.white-text {
  color: white !important;
}

.white {
  background: white;
}

.text-center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.fs-20 {
  font-size: 18px;
}

.black {
  background-color: #1e1e2d;
}

.is-pill {
  padding: 7px 15px;
  font-size: 13px;
  font-weight: 400;
}

/* Autocomplete */
.ng-autocomplete {
  width: 100% !important;
  margin-bottom: 20px;
}
.autocomplete-container {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
  position: relative !important;
  overflow: visible !important;
  height: 40px !important;
}
.autocomplete-container .input-container input {
  font-size: 1rem !important;
  outline: none !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  padding: 1rem 0.7rem !important;
  color: gray !important;
  transition: 0.1s ease-out !important;
  width: 100% !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 0 15px !important;
  line-height: 45px !important;
  height: 45px !important;
}

.autocomplete-container .input-container input:focus {
  border-color: var(--primary-color) !important;
}
.autocomplete-container .input-container input:focus + label {
  color: var(--primary-color) !important;
  top: 0 !important;
  transform: translateY(-50%) scale(0.9) !important;
}
.autocomplete-container .input-container input:not(:placeholder-shown) + label {
  top: 0 !important;
  transform: translateY(-50%) scale(0.9) !important;
}

.teal {
  background: teal;
  color: #ffffff;
}

.red {
  background: #af0000;
  color: #ffffff;
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}

/* Inner page form */
.inner-page .form-holder {
  position: relative;
  width: 100%;
  box-shadow: 1px 5px 24px 0 rgba(0, 0, 0, 0.05) !important;
}
.inner-page .form-holder label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 15px;
  top: 13.3px;
  font-size: 19px;
  color: var(--form-control-placeholder-color);
}
.inner-page .form-holder .form-control {
  height: 45px;
  border-radius: 30px;
  background-color: var(--secondary-color);
  border: none;
  color: var(--accent-color);
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05) !important;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05) !important;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.inner-page .form-holder .form-control:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05) !important;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05) !important;
  box-shadow: unset;
  background-color: var(--secondary-color);
}

.inner-page .form-holder .form-control-dark {
  height: 45px;
  border-radius: 30px;
  background-color: #000000 !important;
  border: none;
  color: var(--accent-color);
  padding: 0 15px 0 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: 1px 5px 24px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: unset;
  font-size: 14px;
  font-weight: 400;
}
.inner-page .form-holder .form-control-dark:focus {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: 1px 5px 24px 0 rgba(0, 0, 0, 0.05) !important;
  /* box-shadow: unset; */
  background-color: #000000 !important;
}

.inner-page .form-holder .form-control:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.inner-page .form-holder .form-control:focus:-ms-input-placeholder {
  color: transparent !important;
}
.inner-page .form-holder .form-control:focus::-ms-input-placeholder {
  color: transparent !important;
}
.inner-page .form-holder .form-control:focus::placeholder {
  color: transparent !important;
}
.inner-page .form-holder .form-control::-webkit-input-placeholder {
  color: var(--form-control-placeholder-color);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.inner-page .form-holder .form-control:-ms-input-placeholder {
  color: var(--form-control-placeholder-color);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.inner-page .form-holder .form-control::-ms-input-placeholder {
  color: var(--form-control-placeholder-color);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.inner-page .form-holder .form-control::placeholder {
  color: var(--form-control-placeholder-color);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.app-small-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-small-btn {
  font-size: 12px;
  display: inline-block;
  border-radius: 50px;
  padding: 0.3rem 0.8rem;
  box-shadow: var(--sidenav-shadow-color);
  text-align: center;
  text-transform: uppercase;
  border: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.app-small-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}

.app-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-toggle-btn {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  background: linear-gradient(
    150deg,
    var(--primary-color-2) 0%,
    var(--primary-color) 100%
  );
  border-radius: 25px;
  padding: 0.4rem 0.8rem;
  color: var(--app-toggle-btn-color);
  box-shadow: var(--sidenav-shadow-color);
  text-align: center;
  text-transform: uppercase;
  border: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.invalid-feedback {
  color: var(--error);
  font-size: 14px;
  margin: 15px;
}

.app-toggle-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}

/* Search bar */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline input,
.form-inline button {
  padding: 1rem;
  border: none;
  outline: none;
  font-size: 18px;
}

.form-inline input {
  vertical-align: middle;
  background-color: #fff;
  border: none !important;
  background: var(--track-input);
}

.form-inline input:first-child {
  border-radius: 50px 0 0 50px;
  padding-left: 20px;
  width: 200px;
}

.form-inline button {
  border-radius: 0 50px 50px 0;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border: none !important;
  padding-right: 20px;
}

.form-inline button:hover {
  background-color: var(--primary-color);
  border: none !important;
}

@media (max-width: 800px) {
  .form-inline input {
    margin: 0;
  }

  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }

  .form-inline input:first-child {
    width: 100%;
    border-radius: 0px 0 0 0px;
    width: 350px;
  }
  .form-inline input,
  .form-inline button {
    width: 350px;
    border-radius: 0 0px 0px 0;
  }

  .form-holder {
    width: 400px;
    margin: 0 auto;
    padding-top: 20px;
  }
}

.form-holder {
  width: 700px;
  margin: 0 auto;
  border-radius: 50px;
  padding-top: 20px;
}

.track-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: -10px;
}
.track-btn {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  background: linear-gradient(
    150deg,
    var(--primary-color-2) 0%,
    var(--primary-color) 100%
  );
  color: var(--secondary-color);
  box-shadow: var(--sidenav-shadow-color);
  border-radius: 25px;
  padding: 0.8rem 1.25rem;
  text-align: center;
  text-transform: uppercase;
  border: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.track-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}

.app-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.app-btn {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  background: linear-gradient(
    150deg,
    var(--primary-color-2) 0%,
    var(--primary-color) 100%
  );
  color: var(--secondary-color);
  box-shadow: var(--sidenav-shadow-color);
  border-radius: 25px;
  padding: 0.8rem 1.25rem;
  text-align: center;
  text-transform: uppercase;
  border: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.app-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}
/* .app-btn:hover::after {
  transform: scale(1.05) scale(1.05);
  opacity: 0;
  transition: all 0.5s;
} */

.app-sec-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.app-sec-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  background: linear-gradient(90deg, #eee 0%, #fff 100%);
  color: var(--accent-color);
  border-radius: 25px;
  padding: 0.8rem 1.25rem;
  text-align: center;
  text-transform: uppercase;
  border: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.app-sec-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}
.app-sec-btn:hover::after {
  transform: scale(1.05) scale(1.05);
  opacity: 0;
  transition: all 0.5s;
}

.fs-30 {
  font-size: 30px;
}
.fs-25 {
  font-size: 25px;
}

/* from dave new ui for auth */

.primary-text {
  color: var(--primary-color);
}
.accent-text {
  color: var(--accent-color);
}
.clickable {
  cursor: pointer;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
.space-evenly {
  display: flex;
  justify-content: space-evenly;
}
.space-center {
  display: flex;
  justify-content: center;
}
.space-end {
  display: flex;
  justify-content: flex-end;
}
.space-start {
  display: flex;
  justify-content: flex-start;
}

.app-shadow {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.auth-slide {
  flex-basis: 50%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  display: none;
}
.auth-slide-heading {
  color: var(--secondary-color);
  position: absolute;
  top: 20%;
  left: 5rem;
  animation: welcome 1500ms both;
}
.auth-slide-heading h1 {
  font-size: 50px;
  color: var(--secondary-color);
}
.auth-slide-heading p {
  font-size: 18px;
}
.auth-slide-heading::after {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  width: 3px;
  height: 100%;
  background-color: var(--primary-color-2);
}
.auth-form-area {
  flex-basis: 50%;
  min-height: 100vh;
  background: var(--secondary-color);
  /* background-position: bottom; */
  /* background-size: cover; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.auth-form-content {
  padding: 10%;
}
.auth-form-area .logo {
  /* position: absolute;
  top: 5%;
  right: 10%; */
  margin-bottom: 280px;
}
.auth-form-heading {
  margin-bottom: 2rem;
  text-align: center;
}
.form-area {
  display: grid;
}
.form-area .form-field {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 1px solid transparent;
  background-color: var(--secondary-color);
  padding: 0 0.6rem;
  transition: all 0.2s ease;
  font-size: 18px !important;
}
.form-area .form-field:not(:first-child) {
  margin-top: 1rem;
}

.form-area .form-field i {
  margin-right: 1rem;
  font-size: 18px;
}
.form-area .form-field input {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0.6rem 0;
}
.form-area .form-field input:active {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0.6rem 0;
  color: var(--text-color);
}
.form-area .form-field input::placeholder {
  font-family: "Quicksand", sans-serif;
}
.form-area .form-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.form-area .form-btn .btn {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  background: linear-gradient(90deg, #023b1e 0%, #019831 100%);
  color: var(--secondary-color);
  border-radius: 25px;
  padding: 0.8rem 1.25rem;
  text-align: center;
  text-transform: uppercase;
  border: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.form-area .form-btn .btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
}
.form-area .form-btn .btn:hover::after {
  transform: scale(1.05) scale(1.05);
  opacity: 0;
  transition: all 0.5s;
}

.auth-form-extra {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.auth-form-extra a {
  color: var(--primary-color);
}
.auth-form-extra a:hover {
  color: var(--accent-color);
}
.auth-form-signup {
  font-weight: 700;
  text-align: center;
  margin-top: 2rem;
}
.auth-form-signup i {
  vertical-align: middle;
  margin-left: 0.3rem;
}

/*/ Media Query /*/
@media only screen and (min-width: 1000px) {
  .auth-slide {
    flex-basis: 60%;
  }
  .auth-form-area {
    flex-basis: 40%;
  }
}
@media only screen and (min-width: 775px) {
  .auth {
    display: flex;
  }
  .auth-slide {
    display: block;
  }
  .auth-form-content {
    padding: 5rem 8rem;
  }
  .auth-form-area .logo {
    right: 5rem;
  }
}

/*/ Keyframes /*/
@keyframes welcome {
  0% {
    transform: translateX(-999px);
  }
  100% {
    transform: translateX(0);
  }
}

.my-20 {
  margin: 20px 0;
}
.pa-20 {
  padding: 20px;
}

.card {
  background-color: #fff;
  -webkit-box-shadow: 1px 5px 18px 0 rgba(68, 102, 242, 0.2);
  box-shadow: 1px 5px 18px 0 rgba(68, 102, 242, 0.2);
  border: none;
  border-radius: 5px;
  padding: 25px;
}

.logoname {
  color: var(--text-color) !important;
  margin-left: 15px;
  font-size: 40px;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 500;
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item.mm-active .nav-link {
  background-color: var(--active-link-bg-color);
  color: var(--primary-color-2);
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link::before {
  background: var(--primary-color-2);
}
.top-navbar.navbar .nav-item .nav-link {
  color: var(--text-color);
}

.top-navbar.navbar .nav-search-form label {
  color: var(--text-color);
}

.top-navbar.navbar .nav-search-form .form-control {
  background-color: var(--secondary-color);
  color: var(--text-color);
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
}

.with-shadow {
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
}
.top-navbar.navbar .nav-search-form .form-control:focus {
  background-color: var(--secondary-color);
}

.top-navbar.navbar .nav-search-form .form-control::-webkit-input-placeholder {
  color: var(--form-control-placeholder-color);
}
.top-navbar.navbar .nav-search-form .form-control:-ms-input-placeholder {
  color: var(--form-control-placeholder-color);
}
.top-navbar.navbar .nav-search-form .form-control::-ms-input-placeholder {
  color: var(--form-control-placeholder-color);
}
.top-navbar.navbar .nav-search-form .form-control::placeholder {
  color: var(--form-control-placeholder-color);
}

.top-navbar.navbar .nav-item .nav-link .bx-chevron-down {
  color: var(--text-color);
}

.top-navbar.navbar .nav-item.dropdown .dropdown-menu {
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
  background-color: var(--sidenav-bg-color);
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  color: var(--text-color);
}
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.active,
.top-navbar.navbar .nav-item.dropdown .dropdown-menu .dropdown-item:active {
  color: var(--text-color);
  background-color: var(--sidenav-bg-color);
}

.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-header {
  background-color: var(--teal);
  color: #fff;
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon {
  background: var(--scaffold-background-color);
  color: var(--teal);
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  span {
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p {
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background-color: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}

.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar .nav-item.notification-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid var(--secondary-color);
}
.top-navbar.navbar
  .nav-item.notification-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: var(--primary-color) !important;
}

.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-header {
  background-color: var(--teal);
  color: #fff;
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .icon {
  background: var(--scaffold-background-color);
  color: var(--teal);
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  span {
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item
  .content
  p {
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background-color: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}

.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(2):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(3):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(4):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5)
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:nth-child(5):hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-body
  .dropdown-item:hover
  .icon {
  background: var(--secondary-color);
  color: var(--text-color);
}
.top-navbar.navbar .nav-item.message-box .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 15px;
  border-top: 1px solid var(--secondary-color);
}
.top-navbar.navbar
  .nav-item.message-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: var(--primary-color) !important;
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-header {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.top-navbar.navbar .nav-item.apps-box .dropdown-menu .dropdown-footer {
  border-top: 1px solid var(--secondary-color);
}
.dropdown-item:hover {
  background: var(--secondary-color) !important;
}
.top-navbar.navbar
  .nav-item.apps-box
  .dropdown-menu
  .dropdown-footer
  .dropdown-item {
  color: var(--primary-color) !important;
}

.top-navbar.navbar .profile-nav-item .menu-profile .name {
  color: var(--text-color);
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-header {
  border-bottom: 1px solid var(--secondary-color);
}

.top-navbar.navbar .profile-nav-item .menu-profile img {
  border: 2.5px solid var(--secondary-color);
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .name {
  color: var(--text-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-header
  .email {
  color: var(--text-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link {
  color: var(--text-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link
  i {
  color: var(--text-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link.active,
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-body
  .nav-item
  .nav-link:active {
  color: var(--text-color);
  background-color: var(--secondary-color);
}
.top-navbar.navbar .profile-nav-item.dropdown .dropdown-menu .dropdown-footer {
  border-top: 1px solid var(--secondary-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link {
  color: var(--accent-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link
  i {
  color: var(--accent-color);
}
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link.active,
.top-navbar.navbar
  .profile-nav-item.dropdown
  .dropdown-menu
  .dropdown-footer
  .nav-item
  .nav-link:active {
  color: var(--text-color);
  background-color: transparent;
  background-color: var(--secondary-color);
}
.top-navbar.navbar.is-sticky {
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
  background: var(--secondary-color) !important;
}
.breadcrumb-area {
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
  background-color: var(--secondary-color);
}
.breadcrumb-area h1 {
  color: var(--text-color);
}
.breadcrumb-area h1::before {
  background: var(--scaffold-background-color);
}
.breadcrumb-area .breadcrumb .item {
  color: var(--text-color);
}
.breadcrumb-area .breadcrumb .item a {
  color: var(--primary-color);
}
.breadcrumb-area .breadcrumb .item a:hover {
  color: var(--primary-color-2);
}
.breadcrumb-area .breadcrumb .item::before {
  color: var(--scaffold-background-color);
}
.stats-card-box {
  margin-bottom: 30px;
  -webkit-box-shadow: var(--sidenav-shadow-color);
  box-shadow: var(--sidenav-shadow-color);
  background-color: var(--sidenav-bg-color);
}
.stats-card-box .icon-box {
  background-color: var(--secondary-color);
  -webkit-box-shadow: 1px 5px 24px 0 var(--sidenav-shadow-color);
  box-shadow: 1px 5px 24px 0 var(--sidenav-shadow-color);
  color: var(--text-color);
}
.stats-card-box .sub-title {
  color: var(--text-color-2);
}
.stats-card-box .progress-list .bar-inner {
  border-bottom: 3px solid var(--secondary-color);
}
.stats-card-box .progress-list .bar-inner .bar {
  background: var(--accent-color);
}
.stats-card-box .progress-list p {
  color: #a3a3a3;
}
.card {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.footer-area {
  border-top: 1px solid var(--sidenav-bg-color);
}
.footer-area p {
  color: var(--text-color-2);
}
.footer-area p a {
  display: inline-block;
  color: var(--text-color-2);
}
.sidemenu-area .sidemenu-body .sidemenu-nav .nav-item .nav-link .icon {
  color: var(--text-color);
  font-size: 20px;
  position: relative;
}

.top-navbar.navbar .responsive-burger-menu span {
  width: 22px;
  background: var(--primary-color);
}
.sidemenu-area .sidemenu-header .responsive-burger-menu span {
  width: 22px;
  background: var(--accent-color);
}

.profile-header .user-profile-nav {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.profile-header .user-profile-images .user-profile-text span {
  color: #fff;
  opacity: 0.9;
  font-size: 17px;
  font-weight: 400;
}
.profile-header .user-profile-nav .nav .nav-item .nav-link.active,
.profile-header .user-profile-nav .nav .nav-item .nav-link:hover {
  background: linear-gradient(
    150deg,
    var(--primary-color-2) 0%,
    var(--primary-color) 100%
  );
  border-radius: 25px;
  color: var(--app-toggle-btn-color);
  box-shadow: var(--sidenav-shadow-color);
}
.profile-header .user-profile-nav .nav .nav-item .nav-link {
  color: var(--text-color-2);
  background-color: var(--scaffold-background-color);
  font-size: 18px;
  border-radius: 25px;
}
.card .card-header {
  background-color: var(--sidenav-bg-color);
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 10px;
  color: var(--text-color-2);
}
.card .card-header h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--text-color);
}
.user-info-box .card-body ul li {
  color: var(--text-color-2);
}
.post-share-content {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.bx {
  color: var(--text-color-2);
}
.icon-5 {
  font-size: 5px;
}
.icon-10 {
  font-size: 10px;
}
.icon-15 {
  font-size: 15px;
}
.icon-20 {
  font-size: 20px;
}
.icon-25 {
  font-size: 25px;
}
.icon-30 {
  font-size: 30px;
}
.icon-35 {
  font-size: 35px;
}
.icon-40 {
  font-size: 40px;
}
.icon-45 {
  font-size: 45px;
}
.icon-50 {
  font-size: 50px;
}
.icon-75 {
  font-size: 75px;
}
.icon-100 {
  font-size: 100px;
}
.icon-150 {
  font-size: 150px;
}
.icon-200 {
  font-size: 200px;
}
.icon-500 {
  font-size: 500px;
}

.inner-page .form-holder .form-control {
  height: 55px;
  border-radius: 30px;
  background-color: var(--secondary-color);
  border: none;
  color: var(--text-color);
  padding: 0 15px 0 40px;
  transition: 0.5s;
  box-shadow: var(--sidenav-shadow-color) !important;
  box-shadow: unset;
  font-size: 18px;
  font-weight: 400;
}
.post-share-content .post-share-footer {
  border-top: 1px solid var(--secondary-color);
}
.btn-icon {
  color: var(--scaffold-background-color) !important;
}

.chat-content-area .sidebar-left .sidebar {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
  color: var(--text-color);
  width: 400px;
}
.chat-content-area .content-right {
  width: calc(100% - 430px);
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control {
  box-shadow: var(--sidenav-shadow-color);
  background-color: var(--secondary-color);
  color: var(--text-color);
}
.chat-content-area
  .sidebar-left
  .sidebar
  .chat-sidebar-header
  form
  .form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  background-color: var(--secondary-color);
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-label {
  color: var(--text-color-2);
  border-bottom: 1px solid var(--secondary-color);
}
.chat-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .chat-menu
  .list-group-user
  li
  span {
  color: var(--text-color-2);
}
.chat-content-area .content-right .chat-list-wrapper .chat-list {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container {
  background-color: var(--sidenav-bg-color);
  /* background-image: url("assets/img/chat-bg.png"); */
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat
  .chat-body
  .chat-message {
  color: #fff;
  background-color: var(--chat-bg-right-color);
  box-shadow: var(--sidenav-shadow-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message {
  color: var(--text-color);
  background-color: var(--chat-bg-left-color);
  box-shadow: var(--sidenav-shadow-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-container
  .chat-content
  .chat.chat-left
  .chat-body
  .chat-message
  p {
  color: var(--text-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer {
  background-color: var(--sidenav-bg-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .emoji-btn {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  .file-attachment-btn {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .form-control {
  background-color: var(--secondary-color);
  border: 1px solid var(--sidenav-bg-color);
  color: var(--text-color);
  box-shadow: var(--sidenav-shadow-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .form-control:focus {
  background-color: var(--secondary-color);
  border: 1px solid var(--sidenav-bg-color);
  color: var(--text-color);
  box-shadow: var(--sidenav-shadow-color);
}

.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .send-btn {
  background-color: #22c1c3;
  color: var(--text-color) !important;
  box-shadow: var(--sidenav-shadow-color);
}
.chat-content-area
  .content-right
  .chat-list-wrapper
  .chat-list
  .chat-list-footer
  form
  .send-btn:hover {
  background-color: var(--accent-color);
  color: var(--text-color) !important;
  box-shadow: var(--sidenav-shadow-color);
}

.email-content-area .sidebar-left .sidebar .sidebar-content {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item:hover {
  color: var(--text-color);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item.active {
  background-color: var(--sidenav-bg-color);
  color: var(--primary-color);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-messages
  .list-group-item {
  background-color: var(--sidenav-bg-color);
  color: var(--text-color-2);
}
.email-content-area .content-right .email-list-wrapper .email-list {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header {
  border-bottom: 1px solid var(--secondary-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-left
  .btn-icon {
  background-color: var(--scaffold-background-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  label {
  color: var(--text-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-header
  .header-right
  .form-control {
  color: var(--text-color);
  background-color: var(--secondary-color);
  box-shadow: var(--sidenav-shadow-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item {
  border-bottom: 1px solid var(--secondary-color);
  background-color: var(--sidenav-bg-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-actions
  .favorite {
  color: var(--text-color-2);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .date {
  color: var(--text-color-2);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .from {
  color: var(--text-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item
  .email-list-detail
  .msg {
  color: var(--text-color-2);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item:hover {
  box-shadow: var(--sidenav-shadow-color);
}

.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read {
  background-color: var(--scaffold-background-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .date {
  color: var(--text-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .from {
  color: var(--text-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .list-wrapper
  .email-list-item.read
  .email-list-detail
  .msg {
  color: var(--text-color-2);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer {
  border-top: 1px solid var(--secondary-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  p {
  color: var(--text-color-2);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button {
  background-color: var(--scaffold-background-color);
}
.email-content-area
  .content-right
  .email-list-wrapper
  .email-list
  .email-list-footer
  .email-pagination-btn
  button {
  background-color: var(--scaffold-background-color);
}
.email-content-area
  .sidebar-left
  .sidebar
  .sidebar-content
  .email-menu
  .list-group-labels
  .list-group-item {
  background-color: var(--sidenav-bg-color);
  color: var(--text-color);
}

.app-send-btn {
  border: none;
  color: #333;
  box-shadow: var(--sidenav-shadow-color);
  background: linear-gradient(
    150deg,
    var(--primary-color-2) 0%,
    var(--primary-color) 100%
  );
  border-radius: 30px;
  height: 38px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 700;
  margin-top: 15px;
}

.modal-content {
  background-color: var(--sidenav-bg-color);
  box-shadow: var(--sidenav-shadow-color);
}
.modal-header {
  border-bottom: 1px solid var(--secondary-color);
}
.modal-footer {
  border-top: 1px solid var(--secondary-color);
}
.modal p {
  color: var(--text-color);
}
.close {
  color: var(--text-color);
  text-shadow: var(--sidenav-shadow-color);
}
.autocomplete-container .input-container input {
  border: 1px solid var(--secondary-color) !important;
  border-radius: 50px !important;
  color: var(--text-color) !important;
  background-color: var(--secondary-color) !important;
}
.autocomplete-container .suggestions-container ul li a {
  color: var(--text-color) !important;
  background: var(--secondary-color) !important;
}
.autocomplete-container .suggestions-container ul li a {
  color: var(--text-color) !important;
}

.page-loader {
  display: flex;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: var(--scaffold-background-color);
}
.spinner {
  width: 100px;
  height: 100px;
  display: grid;
  margin: auto;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: var(--primary-color);
  animation: s5 1s infinite linear;
}
.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: s5 2s infinite;
}
.spinner::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes s5 {
  100% {
    transform: rotate(1turn);
  }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar-error {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: var(--error);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

#snackbar-error.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar-success {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: var(--teal);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

#snackbar-success.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

table {
  width: 100%;
}

.no-color a {
  color: var(--text-color) !important;
  text-decoration: none;
}
.no-color a:hover {
  color: var(--primary-color) !important;
  text-decoration: none;
}

/* Circle Avatar Styles */
.circle {
  line-height: 0; /* remove line-height */
  display: inline-block; /* circle wraps image */
  margin: 5px;
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  transition: linear 0.25s;
  width: 90px;
  height: 90px;
}
.circle img {
  border-radius: 50%;
}
.circle:hover {
  transition: ease-out 0.2s;
  border: 4px solid rgba(0, 0, 0, 0.2);
  -webkit-transition: ease-out 0.2s;
}
a.circle {
  color: transparent;
} /* IE fix: removes blue border */

.small-circle {
  line-height: 0; /* remove line-height */
  display: inline-block; /* small-circle wraps image */
  margin: 5px;
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  transition: linear 0.25s;
  width: 40px;
  height: 40px;
}
.small-circle img {
  border-radius: 50%;
}
.small-circle:hover {
  transition: ease-out 0.2s;
  border: 4px solid rgba(0, 0, 0, 0.2);
  -webkit-transition: ease-out 0.2s;
}
a.small-circle {
  color: transparent;
}

/* #map {
  height: 60vh;
  width: 95vh;
  margin: auto;
} */

.mat-select {
  font-size: 18px !important;
  font-family: "Quicksand", sans-serif !important;
  color: var(--text-color) !important;
}
.mat-form-field {
  font-weight: 300 !important;
  line-height: 1.125;
  font-family: "Quicksand", sans-serif !important;
  letter-spacing: normal;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  display: flex;
  position: absolute;
  top: 0.025em !important;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.mat-form-field-appearance-outline .mat-form-field-flex:focus {
  outline: 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 0.075em 0 0.075em !important;
  margin-top: -0.25em;
  position: relative;
}
.mat-form-field-wrapper {
  padding-bottom: 0.05em !important;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0.0025em 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px !important;
}
::ng-deep .mat-form-field.mat-focused .mat-form-field-underline {
  display: none !important;
}
:host ::ng-deep div.mat-form-field-outline-start {
  border-color: 0 !important;
  border-width: 0px !important;
}

:host ::ng-deep .mat-form-field-outline-gap {
  border-color: 0 !important;
  border-width: 0px !important;
}

:host ::ng-deep .mat-form-field-outline-end {
  border-color: 0 !important;
  border-width: 0px !important;
}

.text-bold {
  font-weight: 700;
}

.wallet-balance {
  font-size: 80px;
  font-weight: 600;
  letter-spacing: 2;
}

.root {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}

figure {
  display: flex;
}
figure img {
  width: 8rem;
  height: 8rem;
  border-radius: 15%;
  border: 1.5px solid var(--primary-color-2);
  margin-right: 1.5rem;
  padding: 1rem;
}
figure figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
figure figcaption h4 {
  font-size: 1.4rem;
  font-weight: 500;
}
figure figcaption h6 {
  font-size: 1rem;
  font-weight: 300;
}
figure figcaption h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.order-track {
  margin-top: 2rem;
  padding: 0 1rem;
  border-top: 1px dashed #2c3e50;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}
.order-track-step {
  display: flex;
  height: 7rem;
}
.order-track-step:last-child {
  overflow: hidden;
  height: 4rem;
}
.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}
.order-track-status {
  margin-right: 1.5rem;
  position: relative;
}
.order-track-status-dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: var(--primary-color-2);
}
.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 7rem;
  background: var(--primary-color-2);
}
.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3px;
}
.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
}

.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.smp {
  font-size: 14px;
}

.isBold {
  font-weight: 700;
}

.isSlim {
  font-weight: 300;
}

.bg-teal {
  background: teal !important;
  color: white !important;
}
.teal-text {
  color: teal !important;
}

.bg-purple {
  background: purple !important;
  color: white !important;
}
.purple-text {
  color: purple !important;
}

.bg-cyan {
  background: cyan !important;
  color: white !important;
}
.cyan-text {
  color: cyan !important;
}

.bg-red {
  background: var(--primary-color-2) !important;
  color: white !important;
}
.red-text {
  color: var(--primary-color-2) !important;
}

.bg-orange {
  background: orangered !important;
  color: white !important;
}
.orange-text {
  color: orangered !important;
}

.bg-whie {
  background: white !important;
  color: white !important;
}
.white-text {
  color: white !important;
}

.bg-green {
  background: green !important;
  color: white !important;
}
.green-text {
  color: green !important;
}
